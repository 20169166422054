@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDZbtY.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vXDZbtY.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebunDZbtY.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiunDZbtY.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFunDZbtY.ttf) format('truetype');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsunDZbtY.ttf) format('truetype');
}
@font-face {
  font-family: "altairthin";
  src: url("../fonts/altair-thintrial-webfont.woff2") format("woff2"), url("../fonts/altair-thintrial-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "altairbold";
  src: url("../fonts/altair.bold-webfont.woff2") format("woff2"), url("../fonts/altair.bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.select-wrapper {
  position: relative;
}
.select-wrapper select {
  cursor: pointer;
  padding: 5px 100px 5px 10px;
  border: 1px solid #fc821b;
  border-radius: 4px;
  background: #fff;
  height: 31px;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 13px;
  appearance: none;
  outline: none;
  display: block;
  color: #666;
  width: 100%;
}
.select-wrapper:after {
  cursor: pointer;
  content: "";
  height: 10px;
  width: 10px;
  background: url("../img/pictures/down-chevron.svg") center;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin-bottom: 0;
}
.checkbox-wrapper .radio-box {
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: none;
  color: #414141;
  font-weight: 300;
  text-align: left;
  transition: all 0.3s;
}
.checkbox-wrapper .radio-box:before {
  content: "";
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  display: block;
  background: url("../img/pictures/checkbox.svg") no-repeat;
  margin-right: 10px;
  transition: all 0.3s;
}
.checkbox-wrapper input {
  visibility: hidden;
  position: absolute;
  width: auto !important;
}
.checkbox-wrapper > input:checked + .radio-box {
  font-weight: 700;
}
.checkbox-wrapper > input:checked + .radio-box:before {
  background: url("../img/pictures/checkbox-checked.svg") no-repeat;
}
.breadcrumb-wrapper {
  padding: 20px 0;
  background: linear-gradient(90deg, #ffffff 35%, #f5f5f5 100%);
  margin-bottom: 30px;
}
.breadcrumb-wrapper .breadcrumb {
  border-radius: 0;
  padding: 0;
  background: none;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-start;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item {
  font-size: 12px;
  font-weight: 400;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a {
  color: #87bb41;
  transition: all 0.4s;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a svg {
  display: none;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a:hover {
  color: #fc821b;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  font-weight: 200;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.products-flexbox.grid-list .product-service {
  width: 100% !important;
  flex-direction: row;
  justify-content: flex-start;
}
.products-flexbox.grid-list .product-service:hover {
  border: 1px solid transparent;
}
.products-flexbox.grid-list .product-service .btn-wrapper {
  justify-content: flex-start;
}
.products-flexbox.grid-list .product-service .btn-wrapper .btn-product:first-of-type {
  margin-right: 20px;
}
.products-flexbox.grid-list .product-service .badge-wrapper {
  top: 0;
}
.products-flexbox.grid-list .product-service header {
  min-width: 17%;
  margin-right: 15px;
}
.products-flexbox.grid-list .product-service .service-image-wrapper.active:after {
  left: auto;
  right: 0;
}
.products-flexbox.grid-list .product-service .name {
  margin-top: 0;
  text-align: left;
}
.products-flexbox.grid-list .product-service .name.grid-view {
  display: none;
}
.products-flexbox.grid-list .product-service .name.list-view {
  display: block;
}
.products-flexbox.grid-list .product-service .price {
  text-align: left;
}
.products-flexbox.grid-list .product-service:not(:first-child) {
  margin-top: 30px;
}
.product-service {
  margin-top: 40px;
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: all 0.4s;
  border: 1px solid transparent;
  padding: 10px;
}
.product-service:hover {
  border: 1px solid #87bb41;
}
.product-service.active {
  border: 1px solid #87bb41;
}
.product-service .badge-wrapper {
  position: absolute;
  z-index: 111;
  top: 10px;
  left: 10px;
  width: 100%;
}
.product-service .badge-wrapper .badge {
  width: 70px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 10px;
}
.product-service .badge-wrapper .badge.promotion {
  background: tomato;
  display: none;
}
.product-service .badge-wrapper .badge.new {
  background: #fcd533;
  display: none;
}
.product-service .badge-wrapper .badge.bestseller {
  background: dodgerblue;
  display: none;
}
.product-service .badge-wrapper .badge.fresh {
  background: olivedrab;
  display: none;
}
.product-service .service-image-wrapper {
  width: 100%;
  padding-top: 100%;
  display: block;
  transition: all 0.4s;
  position: relative;
}
.product-service .service-image-wrapper .image-cover {
  position: absolute;
  z-index: 121;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s;
}
.product-service .service-image-wrapper .image-cover .cover-inner {
  background: #87bb41;
  height: 50px;
  width: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 40px;
}
.product-service .service-image-wrapper .service-image {
  text-align: center;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
}
.product-service .service-image-wrapper .service-image img {
  width: 100%;
}
.product-service .name {
  text-align: center;
  margin-top: 15px;
  color: #222;
  font-weight: 400;
  font-size: 14.5px;
  display: block;
  transition: all 0.4s;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product-service .name.list-view {
  display: none;
}
.product-service .footer {
  position: relative;
  min-height: 155px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.product-service .footer .product-panel-wrapper,
.product-service .footer .product-alert-wrapper {
  position: absolute;
  z-index: 222;
  left: 0;
  bottom: -100%;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(245, 245, 245, 0.95);
  padding: 20px 10px;
  transition: all 350ms;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-service .footer .product-panel-wrapper .panel-button-close,
.product-service .footer .product-alert-wrapper .panel-button-close {
  position: absolute;
  z-index: 333;
  display: block;
  width: 100%;
  top: 5px;
  right: 0;
}
.product-service .footer .product-panel-wrapper .panel-button-close .container-fluid,
.product-service .footer .product-alert-wrapper .panel-button-close .container-fluid {
  display: flex;
  justify-content: flex-end;
}
.product-service .footer .product-panel-wrapper .panel-button-close .container-fluid:before,
.product-service .footer .product-alert-wrapper .panel-button-close .container-fluid:before,
.product-service .footer .product-panel-wrapper .panel-button-close .container-fluid:after,
.product-service .footer .product-alert-wrapper .panel-button-close .container-fluid:after {
  content: none;
}
.product-service .footer .product-panel-wrapper .panel-button-close button,
.product-service .footer .product-alert-wrapper .panel-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  margin-right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.product-service .footer .product-panel-wrapper .panel-button-close button .button-bar,
.product-service .footer .product-alert-wrapper .panel-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 15px;
  height: 1px;
  background: #222;
}
.product-service .footer .product-panel-wrapper .panel-button-close button .button-bar:nth-child(1),
.product-service .footer .product-alert-wrapper .panel-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.product-service .footer .product-panel-wrapper .panel-button-close button .button-bar:nth-child(2),
.product-service .footer .product-alert-wrapper .panel-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.product-service .footer .product-panel-wrapper .alert-wrapper-inner,
.product-service .footer .product-alert-wrapper .alert-wrapper-inner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
}
.product-service .footer .product-panel-wrapper .alert-wrapper-inner p,
.product-service .footer .product-alert-wrapper .alert-wrapper-inner p {
  line-height: 20px;
}
.product-service .footer .product-panel-wrapper.active,
.product-service .footer .product-alert-wrapper.active {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}
.product-service .footer .product-panel-wrapper.success,
.product-service .footer .product-alert-wrapper.success {
  border-top: 5px solid #78b042;
}
.product-service .footer .product-panel-wrapper.warning,
.product-service .footer .product-alert-wrapper.warning {
  border-top: 5px solid #d51a27;
}
.product-service .footer .product-panel-wrapper.info,
.product-service .footer .product-alert-wrapper.info {
  border-top: 5px solid #1a88d5;
}
.product-service .footer .quantity-wrapper {
  margin-top: 10px;
}
.product-service .footer .quantity-wrapper .wrapper-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-service .footer .quantity-wrapper .wrapper-inner .input-number {
  width: 70px;
  padding: 0 12px;
  margin: 0 10px;
  text-align: center;
  outline: none;
  background: #e5e5e5;
  border-radius: 4px;
  border: none;
}
.product-service .footer .quantity-wrapper .wrapper-inner .input-number,
.product-service .footer .quantity-wrapper .wrapper-inner .input-number-decrement,
.product-service .footer .quantity-wrapper .wrapper-inner .input-number-increment {
  height: 40px;
  user-select: none;
}
.product-service .footer .quantity-wrapper .wrapper-inner .input-number-decrement,
.product-service .footer .quantity-wrapper .wrapper-inner .input-number-increment {
  min-width: 40px;
  border-radius: 4px;
  background: #fc821b;
  color: #fff;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.4s;
  cursor: pointer;
  border: none;
  outline: none;
}
.product-service .footer .quantity-wrapper .wrapper-inner .input-number-decrement:active,
.product-service .footer .quantity-wrapper .wrapper-inner .input-number-increment:active {
  background: #87bb41;
  color: #fff;
}
.product-service .footer .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-inner-spin-button,
.product-service .footer .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.product-service .footer .quantity-wrapper .wrapper-inner input[type="number"] {
  -moz-appearance: textfield;
}
.product-service .footer .quantity-wrapper .btn {
  color: #fff;
  background-color: #87bb41;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 13px;
  transition: background-color 0.4s;
  margin-top: 10px;
  padding: 0;
  padding-left: 8px;
  width: 100%;
}
.product-service .footer .quantity-wrapper .btn .btn-icon {
  background: #87bb41;
  font-weight: 700;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.4s;
}
.product-service .footer .quantity-wrapper .btn:hover {
  background-color: #79a83a;
}
.product-service .footer .quantity-wrapper .btn:hover .btn-icon {
  background-color: #79a83a;
}
.product-service .price {
  text-align: center;
  margin-top: 10px;
  color: #87bb41;
  font-size: 18px;
  letter-spacing: -0.5px;
  font-weight: 400;
}
.product-service .price small {
  font-size: 18px;
  font-weight: 400;
  color: #444;
  margin-right: 5px;
  position: relative;
  display: none;
}
.product-service .price small:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1px;
  width: 100%;
  height: 1px;
  background: #999;
  transform: rotate(-10deg);
}
.product-service .price span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
}
.product-service.promotion .promotion {
  display: flex !important;
}
.product-service.promotion .price {
  color: tomato;
}
.product-service.promotion .price small {
  display: inline-block;
}
.product-service.new .new {
  display: flex !important;
}
.product-service.bestseller .bestseller {
  display: flex !important;
}
.product-service.fresh .fresh {
  display: flex !important;
}
.product-service:hover .service-image-wrapper .image-cover {
  opacity: 1;
}
.product-service:hover .name {
  color: #87bb41;
}
.product-service .footer-inner {
  display: flex;
  flex-direction: column;
  min-height: 75px;
}
.product-service .btn-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.product-service .btn-wrapper .btn-product {
  padding: 5px 6px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  max-width: 100%;
  display: flex;
  align-items: center;
  background-color: #87bb41;
  transition: background-color 0.4s;
  cursor: pointer;
  border: none;
}
.product-service .btn-wrapper .btn-product:hover {
  background-color: #6c9534;
}
.product-service .btn-wrapper .btn-product:focus {
  outline: none;
  background-color: #6c9534;
}
.product-service .btn-wrapper .btn-product:first-of-type svg {
  width: 10px;
}
.product-service .btn-wrapper .btn-product svg {
  fill: #fff;
  width: 12px;
  margin-right: 5px;
}
.product-service.service-hidden {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  visibility: hidden;
  opacity: 0;
}
.pagination-nav {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.pagination-nav li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  color: #87bb41;
  text-transform: uppercase;
  transition: all 0.3s;
}
.pagination-nav li:hover a {
  color: #fc821b;
}
.pagination-nav li.active a {
  font-weight: 700;
  color: #fff;
  background: #fc821b;
}
nav.section-header {
  background: #f5f5f5;
}
nav.section-header .button-wrapper .nav-button {
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
  cursor: pointer;
  border: 1px solid #87bb41;
  border-radius: 4px;
  width: 45px;
  height: 45px;
}
nav.section-header .button-wrapper .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #87bb41;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .button-wrapper .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .button-wrapper .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .button-wrapper .nav-button:hover .button-bar,
nav.section-header .button-wrapper .nav-button:focus .button-bar {
  width: 30px !important;
}
nav.section-header.subpage {
  background: #fff;
}
nav.section-header.subpage .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.active a,
nav.section-header.subpage .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover a {
  background: #fff;
}
nav.section-header .header-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 20px 0;
}
nav.section-header .header-management .free-delivery {
  position: absolute;
  top: 10px;
  right: 0;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 300;
  color: #777;
}
nav.section-header .header-management .free-delivery strong {
  font-weight: 600;
  color: #fc821b;
}
nav.section-header .header-management .management-logo img {
  height: 100px;
}
nav.section-header .header-management .management-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 72%;
}
nav.section-header .header-flexbox-wrapper {
  background: #87bb41;
}
nav.section-header .header-flexbox-wrapper .header-flexbox {
  position: relative;
  display: flex;
  justify-content: space-between;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li > a,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li button {
  padding: 0;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;
  outline: none;
  padding: 20px 17px;
  display: block;
  transition: all 0.4s;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.active > a,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover > a,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.active button,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover button {
  background: #f5f5f5;
  color: #87bb41;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child {
  margin-right: 0;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories {
  margin-right: 160px;
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li > a,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li button {
  padding: 0;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: 800;
  font-size: 16px;
  letter-spacing: 0.2px;
  outline: none;
  padding: 20px 17px;
  display: flex;
  align-items: center;
  transition: all 0.4s;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li > a svg,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li button svg {
  fill: #fff;
  height: 20px;
  width: 20px;
  margin-right: 8px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li .dropdown-menu {
  margin: 0;
  border-radius: 0;
  padding: 5px 0;
  border-top: none;
  border-color: #87bb41;
  background: #f5f5f5;
  margin-left: -1px;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li .dropdown-menu .dropdown-item {
  padding: 10px 12px;
  font-size: 15px;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li .dropdown-menu .dropdown-item:first-letter {
  text-transform: uppercase;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li .dropdown-menu .dropdown-item:hover {
  background: #87bb41;
  color: #fff;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li.active button,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li:hover button {
  background: #f5f5f5;
  color: #87bb41;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li.active button svg,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li:hover button svg {
  fill: #87bb41;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li:last-child {
  margin-right: 0;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-social-media {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-social-media p {
  font-size: 12px;
  color: #fff;
  margin-right: 20px;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-social-media ul {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-social-media ul li a img {
  height: 20px;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-social-media ul li:not(:last-child) {
  margin-right: 10px;
}
.management-search-engine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s;
}
.management-search-engine.relative {
  position: relative;
}
.management-search-engine input {
  width: 400px;
  height: 45px;
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: block;
  background: #d9d9d9;
  margin: 0 7px 0 0;
  outline: none;
  transition: all 0.4s;
}
.management-search-engine input:focus {
  border-color: #fc821b;
}
.management-search-engine input::placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #000;
}
.management-search-engine .btn-search {
  height: 45px;
  min-width: 45px;
  background: #87bb41;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(1px);
  transition: background 0.4s;
}
.management-search-engine .btn-search svg {
  width: 18px;
  height: 18px;
  fill: #fff;
}
.management-search-engine .btn-search:hover {
  background: #6c9534;
}
.management-search-engine .search-content {
  position: absolute;
  top: 55px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 444;
  right: 0;
  width: 700px;
  height: 450px;
  overflow-y: auto;
  background-color: #fff;
  padding: 15px;
  transition: all 200ms;
  display: flex;
  justify-content: space-between;
}
.management-search-engine .search-content.active {
  opacity: 1;
  visibility: visible;
  box-shadow: 0 2px 9px 0 rgba(16, 47, 102, 0.16);
}
.management-search-engine .search-content .products-content {
  width: 60%;
  padding-right: 30px;
}
.management-search-engine .search-content .products-content .btn-search-default {
  padding: 5px 6px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  max-width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: #87bb41;
  transition: background-color 0.4s;
  cursor: pointer;
  border: none;
}
.management-search-engine .search-content .products-content .btn-search-default:hover {
  background-color: #6c9534;
}
.management-search-engine .search-content .products-content .btn-search-default:focus {
  outline: none;
  background-color: #6c9534;
}
.management-search-engine .search-content .products-content .btn-search-default:first-of-type svg {
  width: 10px;
}
.management-search-engine .search-content .products-content .btn-search-default svg {
  fill: #fff;
  width: 12px;
  margin-right: 5px;
}
.management-search-engine .search-content .products-content .product-panel-wrapper,
.management-search-engine .search-content .products-content .product-alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.management-search-engine .search-content .products-content .product-panel-wrapper.active,
.management-search-engine .search-content .products-content .product-alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .wrapper-content,
.management-search-engine .search-content .products-content .product-alert-wrapper .wrapper-content {
  pointer-events: visible;
  padding: 50px;
  position: relative;
  width: 400px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .wrapper-content.success,
.management-search-engine .search-content .products-content .product-alert-wrapper .wrapper-content.success {
  border-top: 5px solid #78b042;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .wrapper-content.warning,
.management-search-engine .search-content .products-content .product-alert-wrapper .wrapper-content.warning {
  border-top: 5px solid #d51a27;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .wrapper-content.info,
.management-search-engine .search-content .products-content .product-alert-wrapper .wrapper-content.info {
  border-top: 5px solid #1a88d5;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .btn-search-default,
.management-search-engine .search-content .products-content .product-alert-wrapper .btn-search-default {
  margin-top: 10px;
  padding: 5px 20px;
  font-size: 13px;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .panel-button-close,
.management-search-engine .search-content .products-content .product-alert-wrapper .panel-button-close {
  position: absolute;
  z-index: 333;
  display: block;
  width: 100%;
  top: 5px;
  right: 0;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .panel-button-close .container-fluid,
.management-search-engine .search-content .products-content .product-alert-wrapper .panel-button-close .container-fluid {
  display: flex;
  justify-content: flex-end;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .panel-button-close .container-fluid:before,
.management-search-engine .search-content .products-content .product-alert-wrapper .panel-button-close .container-fluid:before,
.management-search-engine .search-content .products-content .product-panel-wrapper .panel-button-close .container-fluid:after,
.management-search-engine .search-content .products-content .product-alert-wrapper .panel-button-close .container-fluid:after {
  content: none;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .panel-button-close .btn-panel-close,
.management-search-engine .search-content .products-content .product-alert-wrapper .panel-button-close .btn-panel-close {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  margin-right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .panel-button-close .btn-panel-close .button-bar,
.management-search-engine .search-content .products-content .product-alert-wrapper .panel-button-close .btn-panel-close .button-bar {
  display: block;
  position: absolute;
  width: 15px;
  height: 1px;
  background: #222;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .panel-button-close .btn-panel-close .button-bar:nth-child(1),
.management-search-engine .search-content .products-content .product-alert-wrapper .panel-button-close .btn-panel-close .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.management-search-engine .search-content .products-content .product-panel-wrapper .panel-button-close .btn-panel-close .button-bar:nth-child(2),
.management-search-engine .search-content .products-content .product-alert-wrapper .panel-button-close .btn-panel-close .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.management-search-engine .search-content .products-content .product-panel-wrapper p,
.management-search-engine .search-content .products-content .product-alert-wrapper p {
  line-height: 26px;
  font-size: 15px;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .alert-wrapper-inner,
.management-search-engine .search-content .products-content .product-alert-wrapper .alert-wrapper-inner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner .input-number,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner .input-number {
  width: 70px;
  padding: 0 12px;
  margin: 0 10px;
  text-align: center;
  outline: none;
  background: #e5e5e5;
  border-radius: 4px;
  border: none;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner .input-number,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner .input-number,
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner .input-number-decrement,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner .input-number-decrement,
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner .input-number-increment,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner .input-number-increment {
  height: 40px;
  user-select: none;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner .input-number-decrement,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner .input-number-decrement,
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner .input-number-increment,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner .input-number-increment {
  min-width: 40px;
  border-radius: 4px;
  background: #fc821b;
  color: #fff;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.4s;
  cursor: pointer;
  border: none;
  outline: none;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner .input-number-decrement:active,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner .input-number-decrement:active,
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner .input-number-increment:active,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner .input-number-increment:active {
  background: #87bb41;
  color: #fff;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-inner-spin-button,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-inner-spin-button,
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-outer-spin-button,
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.management-search-engine .search-content .products-content .product-panel-wrapper .quantity-wrapper .wrapper-inner input[type="number"],
.management-search-engine .search-content .products-content .product-alert-wrapper .quantity-wrapper .wrapper-inner input[type="number"] {
  -moz-appearance: textfield;
}
.management-search-engine .search-content .products-content .btn-wrapper {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.management-search-engine .search-content .phrases-content {
  width: 40%;
}
.management-search-engine .search-content .phrases-content ul li a {
  font-size: 15px;
}
.management-search-engine .search-content .heading {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.management-search-engine .search-content .heading h3 {
  font-size: 13px;
  display: inline-block;
}
.management-search-engine .search-content .heading .line {
  width: 40px;
  height: 0.5px;
  background-color: #fc821b;
  margin-left: 10px;
}
.management-search-engine .search-content ul {
  list-style: none;
}
.management-search-engine .search-content ul li {
  padding: 8px 5px;
  transition: transform 250ms;
}
.management-search-engine .search-content ul li a {
  font-size: 12px;
  color: #000;
  display: flex;
}
.management-search-engine .search-content ul li a img {
  width: 40px;
  margin-right: 8px;
}
.management-search-engine .search-content ul li:hover {
  background-color: #f5f5f5;
}
.management-service {
  display: flex;
  align-items: center;
}
.management-service li a {
  display: flex;
  align-items: center;
}
.management-service li a .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  min-width: 45px;
  width: 45px;
  background: #87bb41;
  border-radius: 4px;
  transition: all 0.4s;
}
.management-service li a .icon-wrapper svg {
  height: 20px;
  width: 20px;
  fill: #fff;
  transition: all 0.4s;
}
.management-service li a .name {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 300;
  color: #87bb41;
}
.management-service li a .name strong {
  font-weight: 600;
}
.management-service li:not(:last-child) {
  margin-right: 40px;
}
div.section-header-fixed-search {
  position: fixed;
  z-index: 333;
  top: -150px;
  left: 0;
  width: 100%;
  background: #f5f5f5;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  transition: all 0.4s;
}
div.section-header-fixed-search .search-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
div.section-header-fixed-search .search-inner .inner-logo img {
  height: 40px;
}
div.section-header-fixed-search .search-inner .management-search-engine input {
  width: 500px;
}
div.section-header-fixed-search.active {
  top: 0;
}
section.section-welcome {
  position: relative;
  background: #f5f5f5;
}
section.section-welcome .welcome-image {
  right: 0;
  top: 0;
  height: 100%;
  position: absolute;
}
section.section-welcome .overlay-img {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}
section.section-welcome .owl-carousel-banner .owl-controls .owl-dots {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
section.section-welcome .owl-carousel-banner .owl-controls .owl-dots .owl-dot {
  margin: 5px;
}
section.section-welcome .owl-carousel-banner .owl-controls .owl-dots .owl-dot span {
  background: transparent;
  border: 1px solid #fff;
  width: 8px;
  height: 8px;
  margin: 2.5px;
  transition: all 0.3s;
}
section.section-welcome .owl-carousel-banner .owl-controls .owl-dots .owl-dot.active span {
  margin: 0;
  background: #87bb41;
  border: 1px solid transparent;
  width: 13px;
  height: 13px;
}
section.section-welcome .owl-carousel-banner .owl-controls .owl-nav {
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
section.section-welcome .owl-carousel-banner .owl-controls .owl-nav .owl-prev {
  margin: 0 0 50px 0;
  background: none;
  padding: 0;
  position: relative;
  z-index: 333;
}
section.section-welcome .owl-carousel-banner .owl-controls .owl-nav .owl-prev:before {
  content: "";
  width: 20px;
  height: 37px;
  background: url("../img/pictures/prev.svg") center;
  background-size: cover;
  display: block;
}
section.section-welcome .owl-carousel-banner .owl-controls .owl-nav .owl-next {
  margin: 0 0 50px 0;
  background: none;
  padding: 0;
  position: relative;
  z-index: 333;
}
section.section-welcome .owl-carousel-banner .owl-controls .owl-nav .owl-next:before {
  content: "";
  width: 20px;
  height: 37px;
  background: url("../img/pictures/next.svg") center;
  background-size: cover;
  display: block;
}
section.section-welcome .welcome-inner {
  position: relative;
  height: 290px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
section.section-welcome .welcome-inner .owl-carousel-welcome .owl-controls {
  display: none;
}
section.section-welcome .welcome-inner h1,
section.section-welcome .welcome-inner h2 {
  color: #87bb41;
  font-size: 30px;
  font-weight: 300;
}
section.section-welcome .welcome-inner h1 strong,
section.section-welcome .welcome-inner h2 strong {
  font-size: 70px;
  font-weight: 500;
  font-family: "Playfair Display", serif;
}
section.section-welcome .welcome-inner a {
  margin-top: 20px;
  padding-left: 4px;
  color: #333;
  display: flex;
  align-items: center;
}
section.section-welcome .welcome-inner a .btn-icon {
  background: #b9b9b9;
  height: 23px;
  width: 23px;
  border-radius: 4px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}
section.section-welcome .welcome-inner a .btn-icon img {
  height: 12px;
}
section.section-welcome .welcome-inner a:hover .btn-icon {
  margin-left: 18px;
  background: #87bb41;
}
.owl-carousel .products-flexbox {
  margin-top: -40px;
}
.owl-carousel .products-flexbox .product-service {
  width: 100%;
}
.owl-carousel .owl-controls .owl-nav {
  top: -60px;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
}
.owl-carousel .owl-controls .owl-nav .owl-prev {
  margin: 0 10px 0 0;
  background: #eee;
  padding: 4px 12px;
  position: relative;
  z-index: 333;
}
.owl-carousel .owl-controls .owl-nav .owl-prev:before {
  content: "";
  width: 7px;
  height: 12px;
  background: url("../img/pictures/prev.svg") center;
  background-size: cover;
  display: block;
}
.owl-carousel .owl-controls .owl-nav .owl-next {
  margin: 0;
  background: #eee;
  padding: 4px 12px;
  position: relative;
  z-index: 333;
}
.owl-carousel .owl-controls .owl-nav .owl-next:before {
  content: "";
  width: 7px;
  height: 12px;
  background: url("../img/pictures/next.svg") center;
  background-size: cover;
  display: block;
}
.owl-carousel .owl-controls .owl-dots {
  margin-top: 20px;
}
.owl-carousel .owl-controls .owl-dots .owl-dot.active span {
  background: #87bb41;
}
section.section-products {
  padding-top: 40px;
}
section.section-products .section-heading {
  margin: 0 0 25px 0;
  text-align: center;
  font-family: "altairbold";
  color: #333;
  font-size: 28px;
}
section.section-cta {
  padding-top: 75px;
}
section.section-cta .cta-inner-wrapper {
  padding: 50px 150px;
  border-radius: 4px;
}
section.section-cta .cta-inner-wrapper .cta-inner {
  background: rgba(252, 130, 27, 0.8);
  padding: 50px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}
section.section-cta .cta-inner-wrapper .cta-inner h2 {
  font-family: "altairbold";
  color: #fff;
}
section.section-cta .cta-inner-wrapper .cta-inner p {
  font-size: 14px;
  margin-top: 30px;
  color: #fff;
}
section.section-cta .cta-inner-wrapper .cta-inner a {
  margin-top: 20px;
  padding-left: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-cta .cta-inner-wrapper .cta-inner a .btn-icon {
  background: #b9b9b9;
  height: 23px;
  width: 23px;
  border-radius: 4px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}
section.section-cta .cta-inner-wrapper .cta-inner a .btn-icon img {
  height: 12px;
}
section.section-cta .cta-inner-wrapper .cta-inner a:hover .btn-icon {
  margin-left: 18px;
  background: #87bb41;
}
section.section-tags {
  padding-top: 60px;
}
section.section-tags .section-heading {
  margin: 0 0 25px 0;
  text-align: center;
  font-family: "altairbold";
  color: #333;
  font-size: 28px;
}
section.section-tags .tags-inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
section.section-tags .tags-inner .inner-service {
  margin: 5px;
  display: block;
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 15px;
  color: #fff;
  background: #87bb41;
  transition: all 0.4s;
}
section.section-tags .tags-inner .inner-service:hover {
  background: #6c9534;
}
section.section-tags .tags-inner .inner-service:first-letter {
  text-transform: uppercase;
}
section.section-related {
  margin-top: 75px;
  padding: 75px 0;
  background: #f5f5f5;
  position: relative;
}
section.section-related .img-absolute {
  position: absolute;
  height: 70%;
  bottom: -50px;
  left: -50px;
}
section.section-related .related-flexbox {
  position: relative;
  display: flex;
  justify-content: space-between;
}
section.section-related .related-flexbox .service-heading-mobile {
  font-family: "altairbold";
  color: #333;
  font-size: 19px;
  display: none;
}
section.section-related .related-flexbox .flexbox-service {
  width: 30%;
}
section.section-related .related-flexbox .flexbox-service .service-heading {
  font-family: "altairbold";
  color: #333;
  font-size: 19px;
}
section.section-related .related-flexbox .flexbox-service .service-product {
  margin-top: 20px;
  display: flex;
  padding: 12px;
  background: #fff;
  border-radius: 4px;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-image-wrapper {
  min-width: 100px;
  height: 100px;
  display: block;
  transition: all 0.4s;
  position: relative;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-image-wrapper .image-cover {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-image-wrapper .image-cover .cover-inner {
  background: #87bb41;
  height: 50px;
  width: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 40px;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-image-wrapper .service-image {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-image-wrapper .service-image img {
  width: 100%;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-description {
  padding-left: 15px;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-description .name {
  color: #222;
  font-weight: 400;
  font-size: 14.5px;
  display: block;
  transition: all 0.4s;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-description .name.list-view {
  display: none;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-description .price {
  margin-top: 10px;
  color: #87bb41;
  font-size: 18px;
  letter-spacing: -0.5px;
  font-weight: 400;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-description .price small {
  font-size: 18px;
  font-weight: 400;
  color: #444;
  margin-right: 5px;
  position: relative;
  display: none;
}
section.section-related .related-flexbox .flexbox-service .service-product .service-description .price small:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1px;
  width: 100%;
  height: 1px;
  background: #999;
  transform: rotate(-10deg);
}
section.section-related .related-flexbox .flexbox-service .service-product .service-description .price span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
}
section.section-related .related-flexbox .flexbox-service .service-product.promotion .price {
  color: tomato;
}
section.section-related .related-flexbox .flexbox-service .service-product.promotion .price small {
  display: inline-block;
}
section.section-related .related-flexbox .flexbox-service .service-product:hover .service-image-wrapper .image-cover {
  opacity: 1;
}
section.section-related .related-flexbox .flexbox-service .service-product:hover .name {
  color: #87bb41;
}
section.kategoria-section-content {
  padding: 0 0 75px 0;
}
section.kategoria-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar {
  width: 20%;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading {
  margin-bottom: 15px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading p {
  font-size: 18px;
  font-family: "altairbold";
  color: #333;
  transform: translateY(1px);
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button {
  outline: none;
  padding: 10px;
  width: 100%;
  border: none;
  font-size: 11px;
  text-transform: uppercase;
  background: #87bb41;
  color: #fff;
  transition: all 0.3s;
  display: none;
  cursor: pointer;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button:hover {
  background: #87bb41;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper {
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container:before,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container:after {
  content: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav {
  padding-left: 17px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li {
  list-style: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a {
  color: #333;
  font-size: 17px;
  transition: all 0.4s;
  display: block;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a:hover {
  color: #87bb41;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li.active > a {
  font-weight: 700;
  color: #87bb41;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li:not(:last-child) {
  padding-bottom: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul {
  padding-left: 17px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul li {
  padding-bottom: 0 !important;
  border-bottom: none !important;
  margin-bottom: 0 !important;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul li a {
  padding: 5px 0;
  font-size: 14px;
  color: #777;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav {
  margin-top: 50px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .heading:not(:first-of-type) {
  margin-top: 50px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav ul {
  padding-left: 17px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"] {
  border: none;
  margin: 0 5px;
  outline: none;
  text-align: center;
  font-size: 14px;
  height: 30px;
  border-bottom: 1px solid #fc821b;
  border-radius: 4px;
  width: 100%;
  -moz-appearance: textfield;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]::-webkit-outer-spin-button,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]:invalid,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]:out-of-range {
  border: 1px solid #ff6347;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper span {
  display: block;
  min-width: 12px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper p {
  font-size: 13px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider svg,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"] {
  position: absolute;
  left: 0;
  bottom: -40px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]:focus {
  outline: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #cba42d;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]:focus::-ms-fill-lower {
  background: #cba42d;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]:focus::-ms-fill-upper {
  background: #cba42d;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: #87bb41;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #87bb41;
  height: 14px;
  width: 14px;
  border-radius: 25px;
  background: #fc821b;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #87bb41;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]::-moz-range-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #87bb41;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #fc821b;
  cursor: pointer;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]::-ms-fill-lower,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]::-ms-fill-upper {
  background: #87bb41;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type="range"]::-ms-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #87bb41;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #fc821b;
  cursor: pointer;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper {
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper .btn-clear {
  color: #444;
  border: none;
  font-size: 12px;
  font-weight: 600;
  background: none;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper .btn-filter {
  padding: 0;
  border: none;
  background: #87bb41;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  color: #fff;
  padding: 3px 10px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper .btn-filter:hover {
  background: #6c9534;
}
section.kategoria-section-content .content-flexbox .flexbox-body {
  width: 75%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-text {
  font-size: 13px;
  color: #777;
  position: relative;
  text-align: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper {
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid {
  margin-left: 30px;
  display: flex;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button {
  margin-right: 7px;
  padding: 0;
  border: none;
  background: #fff;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a img,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button img {
  width: 15px;
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a:last-child,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button:last-child {
  margin-right: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a.active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button.active {
  background: #fc821b;
}
section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox .product-service {
  width: 32%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading {
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  font-family: "altairbold";
  color: #333;
  font-size: 28px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta {
  margin-top: 40px;
  display: block;
  position: relative;
  transition: border-radius 0.4s;
  overflow: hidden;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta img {
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta .cta-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  padding: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta .cta-inner p {
  font-weight: 300;
  font-size: 24px;
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta .cta-inner p strong {
  font-size: 31px;
  font-weight: 700;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta:hover {
  border-radius: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-more {
  margin-top: 65px;
  text-align: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-more .btn {
  width: 400px;
  max-width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery {
  width: 38%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery img {
  width: 100%;
  border-radius: 4px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSSlideWrapper {
  border-radius: 4px;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSAction a {
  margin-top: 0;
  background: rgba(0, 0, 0, 0);
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSAction a:hover {
  background: rgba(0, 0, 0, 0.1);
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSAction .lSPrev {
  width: 25px;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transform: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSAction .lSPrev:after {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  background: url("../library/lightslider-master/dist/img/prev.svg") center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSAction .lSNext {
  width: 25px;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
  transform: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSAction .lSNext:after {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  background: url("../library/lightslider-master/dist/img/prev.svg") center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery {
  margin-top: 23px !important;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery li {
  border: 1px solid transparent;
  opacity: 0.6;
  transition: all 0.3s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery li.active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery li:hover {
  opacity: 1;
  border: 1px solid #fc821b;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info {
  width: 55%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper {
  display: flex;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper .badge {
  width: 70px;
  height: 25px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper .badge.promotion {
  background: tomato;
  display: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper .badge.new {
  background: olivedrab;
  display: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper .badge.bestseller {
  background: dodgerblue;
  display: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .badge-wrapper .badge:not(:last-child) {
  margin-right: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info h1 {
  font-size: 36px;
  font-weight: 400;
  color: #87bb41;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .code {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 300;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .code strong {
  font-weight: 700;
  color: #87bb41;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #e5e5e5;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description p {
  font-size: 14px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description ul {
  padding-left: 17px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description ul li {
  font-size: 14px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features > *:not(:first-child) {
  margin-top: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features p {
  font-size: 16px;
  font-weight: 300;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features p strong {
  font-weight: 700;
  color: #999;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox {
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox label {
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;
  margin-bottom: 0;
  margin-right: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox .select-wrapper {
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list li a {
  display: block;
  background: rgba(247, 247, 247, 0.9372549);
  padding: 8px 10px;
  font-size: 13px;
  border-left: 2px solid transparent;
  color: #333;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list li a:hover {
  border-color: #fc821b;
  color: #87bb41;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list li.heading {
  margin-top: 0;
  text-align: left;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list li:nth-child(even) a {
  background: #efefef;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price {
  margin-top: 40px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-brutto {
  color: #87bb41;
  font-size: 40px;
  font-weight: 500;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-brutto small {
  font-size: 40px;
  font-weight: 400;
  color: #444;
  margin-right: 15px;
  position: relative;
  display: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-brutto small:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1px;
  width: 100%;
  height: 1px;
  background: #999;
  transform: rotate(-10deg);
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-brutto span {
  font-weight: 400;
  color: #999;
  font-size: 15px;
  line-height: 15px;
  padding-left: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper {
  margin-top: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number {
  width: 70px;
  padding: 0 12px;
  margin: 0 10px;
  text-align: center;
  outline: none;
  background: #e5e5e5;
  border-radius: 4px;
  border: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment {
  height: 40px;
  user-select: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment {
  min-width: 40px;
  border-radius: 4px;
  background: #fc821b;
  color: #fff;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.4s;
  cursor: pointer;
  border: none;
  outline: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement:active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment:active {
  background: #87bb41;
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-inner-spin-button,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"] {
  -moz-appearance: textfield;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn {
  color: #fff;
  background-color: #87bb41;
  padding: 0 0 0 10px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  transition: background-color 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn .btn-icon {
  background: #87bb41;
  font-weight: 700;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn:hover {
  background-color: #79a83a;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn:hover .btn-icon {
  background-color: #79a83a;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.promotion .promotion {
  display: flex !important;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.promotion .price .price-brutto {
  color: tomato;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.promotion .price .price-brutto small {
  display: inline-block;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.new .new {
  display: flex !important;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info.bestseller .bestseller {
  display: flex !important;
}
section.kontakt-section-welcome {
  padding: 0 0 75px 0;
  overflow: hidden;
  position: relative;
}
section.kontakt-section-welcome .welcome-heading {
  z-index: 111;
  position: relative;
  display: flex;
  justify-content: space-between;
}
section.kontakt-section-welcome .welcome-heading h1 {
  font-size: 60px;
  font-family: "altairthin", sans-serif;
  color: #333;
}
section.kontakt-section-welcome .welcome-heading h1 strong {
  font-size: 45px;
  color: #87bb41;
  font-family: "altairbold", sans-serif;
}
section.kontakt-section-welcome .welcome-heading h1 strong span {
  color: #fc821b;
}
section.kontakt-section-welcome .welcome-heading .heading-contact {
  text-align: right;
  font-family: "Montserrat", sans-serif;
}
section.kontakt-section-welcome .welcome-heading .heading-contact address {
  font-size: 17px;
}
section.kontakt-section-welcome .welcome-heading .heading-contact address strong {
  font-size: 21px;
}
section.kontakt-section-welcome .welcome-heading .heading-contact ul {
  list-style: none;
}
section.kontakt-section-welcome .welcome-heading .heading-contact ul li a {
  font-size: 22px;
  color: #87bb41;
  font-weight: 700;
}
section.kontakt-section-welcome .welcome-maps {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
section.kontakt-section-welcome .welcome-maps .map-service {
  width: 31%;
}
section.kontakt-section-welcome .welcome-maps .map-service .service-description {
  text-align: center;
}
section.kontakt-section-welcome .welcome-maps .map-service .service-description .name {
  font-size: 21px;
}
section.kontakt-section-welcome .welcome-maps .map-service .service-description address {
  font-size: 17px;
}
section.kontakt-section-welcome .welcome-maps .map-service .service-description ul {
  list-style: none;
}
section.kontakt-section-welcome .welcome-maps .map-service .service-description ul li a {
  font-size: 15px;
  color: #87bb41;
  font-weight: 700;
}
section.kontakt-section-welcome .welcome-maps .map-service .service-iframe {
  margin-top: 20px;
  position: relative;
  height: 200px;
}
section.kontakt-section-welcome .welcome-maps .map-service .service-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 12px;
}
section.static-page-section-content {
  padding: 0 0 70px 0;
}
section.static-page-section-content .content-heading {
  text-align: center;
}
section.static-page-section-content .content-heading h2 {
  font-size: 36px;
  font-weight: 400;
  font-family: "altairbold";
}
section.static-page-section-content .content-heading .date {
  margin-top: 15px;
  color: #87bb41;
}
section.static-page-section-content .content-heading .date strong {
  margin-right: 10px;
}
section.static-page-section-content .content-inner {
  margin-top: 40px;
}
section.static-page-section-content .content-inner > *:not(:first-child) {
  margin-top: 20px;
}
section.static-page-section-content .content-inner p,
section.static-page-section-content .content-inner li {
  font-size: 15px;
  line-height: 26px;
  color: #444;
}
section.static-page-section-content .content-inner ul,
section.static-page-section-content .content-inner ol {
  padding-left: 17px;
}
section.static-page-section-content .content-inner .btn-default {
  color: #fff;
  background-color: #87bb41;
  padding: 7px 10px;
  transition: background-color 0.4s;
  display: inline-block;
}
section.static-page-section-content .content-inner .btn-default:hover {
  background-color: #6c9534;
}
section.static-page-section-content .media-flexbox {
  padding-top: 50px;
  margin-top: calc(-5% / 4);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.static-page-section-content .media-flexbox .flexbox-service-wrapper {
  margin-top: calc(5% / 4);
  border-radius: 2px;
  width: 19%;
  position: relative;
  overflow: hidden;
}
section.static-page-section-content .media-flexbox .flexbox-service-wrapper .flexbox-service {
  width: 100%;
  padding-top: 66%;
  display: block;
}
section.static-page-section-content .media-flexbox .flexbox-service-wrapper .flexbox-service .service-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
section.static-page-section-content .media-flexbox .flexbox-service-wrapper .flexbox-service .service-inner img {
  width: 100%;
  transition: all 0.3s;
}
section.static-page-section-content .media-flexbox .flexbox-service-wrapper.service-hidden {
  margin-top: 0;
}
section.section-footer {
  background: #f5f5f5;
  position: relative;
}
section.section-footer .footer-advantages-wrapper {
  background: #87bb41;
  padding: 17px 0;
}
section.section-footer .footer-advantages-wrapper .footer-advantages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
section.section-footer .footer-advantages-wrapper .footer-advantages .advantage-service {
  display: flex;
  align-items: center;
  text-align: center;
}
section.section-footer .footer-advantages-wrapper .footer-advantages .advantage-service img {
  height: 40px;
  margin-right: 13px;
  transition: all 0.4s;
}
section.section-footer .footer-advantages-wrapper .footer-advantages .advantage-service p {
  font-size: 13px;
  color: #fff;
  line-height: 16px;
  font-weight: 300;
  transition: color 0.4s;
}
section.section-footer .footer-advantages-wrapper .footer-advantages .advantage-service p strong {
  font-weight: 600;
}
section.section-footer .footer-advantages-wrapper .footer-advantages .advantage-service:hover img {
  transform: translateY(-4px);
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .flexbox-service {
  margin-top: 75px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-logo img {
  height: 80px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-description {
  margin-top: 20px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-description p {
  color: #444;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 5px;
}
section.section-footer .footer-flexbox .flexbox-service ul li.heading {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 18px;
  color: #444;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 13px;
  color: #444;
  transition: all 0.3s;
}
section.section-footer .footer-flexbox .flexbox-service ul li a b {
  font-size: 16px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #fc821b;
}
section.section-footer .footer-flexbox .flexbox-service ul li p {
  font-size: 12px;
  color: #444;
}
section.section-footer .footer-flexbox .flexbox-service ul li p b {
  font-weight: 400;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li p span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 25px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .alert {
  position: relative;
  background: none;
  border: none;
  border-radius: 4px;
  margin-top: 55px;
  padding: 30px;
  background: #e5e5e5;
}
section.section-footer .alert p {
  font-size: 14px;
  color: #444;
  font-weight: 300;
}
section.section-footer .alert p a {
  color: #fc821b;
  cursor: pointer;
}
section.section-footer .footer-credits-wrapper {
  margin-top: 55px;
  padding: 25px 0;
  background: #e5e5e5;
}
section.section-footer .footer-credits-wrapper .footer-credits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits-wrapper .footer-credits p {
  font-size: 13px;
  color: #444;
  font-weight: 300;
}
section.section-footer .footer-credits-wrapper .footer-credits p a {
  color: #fc821b;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment {
  display: flex;
  align-items: center;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment p {
  margin-right: 15px;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment img {
  margin-right: 10px;
  height: 19px;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment img:last-child {
  margin-right: 0;
}
@media (max-width: 1199.98px) {
  section.section-welcome {
    overflow: hidden;
  }
  section.section-welcome .welcome-image {
    right: -140px;
  }
  .products-flexbox .product-service {
    width: 48%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox .product-service {
    width: 48%;
  }
}
@media (max-width: 991.98px) {
  nav.section-header .header-management {
    padding: 30px 0;
    align-items: flex-end;
  }
  nav.section-header .header-management .management-logo {
    margin-right: 20px;
  }
  nav.section-header .header-management .management-logo img {
    height: 70px;
  }
  nav.section-header .header-management .management-service {
    margin-left: 6px;
  }
  nav.section-header .header-management .management-service li:not(:last-child) {
    margin-right: 12px;
  }
  nav.section-header .header-management .management-search-engine input {
    width: 200px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories {
    margin-right: 0;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-categories li button {
    font-size: 13px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 13px;
    padding: 20px 14px;
  }
  div.section-header-fixed-search .search-inner .management-search-engine input {
    width: 250px;
  }
  .products-flexbox.grid-list .product-service header {
    min-width: 25%;
  }
  section.section-related {
    padding: 30px 0 75px 0;
  }
  section.section-related .related-flexbox {
    flex-direction: column;
  }
  section.section-related .related-flexbox .service-heading-mobile {
    display: block;
    text-align: center;
    margin-top: 30px;
  }
  section.section-related .related-flexbox .service-heading {
    display: none;
  }
  section.section-related .related-flexbox .flexbox-service {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  section.section-related .related-flexbox .flexbox-service .service-product {
    flex-direction: column;
    width: 32%;
  }
  section.section-related .related-flexbox .flexbox-service .service-product .service-image-wrapper {
    height: 150px;
    min-width: 130px;
  }
  section.section-related .related-flexbox .flexbox-service .service-product .service-description {
    padding-left: 0;
    padding-top: 10px;
  }
  section.section-related .related-flexbox .flexbox-service .service-product .service-description .name,
  section.section-related .related-flexbox .flexbox-service .service-product .service-description .price {
    text-align: center;
  }
  section.kontakt-section-welcome .welcome-maps {
    flex-direction: column;
  }
  section.kontakt-section-welcome .welcome-maps .map-service {
    display: flex;
    align-items: center;
    width: 100%;
  }
  section.kontakt-section-welcome .welcome-maps .map-service:not(:first-child) {
    margin-top: 30px;
  }
  section.kontakt-section-welcome .welcome-maps .map-service .service-description {
    width: 40%;
    padding-right: 20px;
  }
  section.kontakt-section-welcome .welcome-maps .map-service .service-iframe {
    width: 60%;
    margin-top: 0;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    margin-top: 20px;
  }
  section.section-footer .footer-flexbox .flexbox-service ul {
    text-align: center;
  }
  section.section-footer .footer-flexbox .flexbox-service ul.list-social {
    justify-content: center;
  }
  section.section-footer .footer-credits-wrapper .footer-credits,
  section.section-footer .footer-credits-wrapper .footer-payment {
    flex-direction: column;
  }
  section.section-footer .footer-credits-wrapper .footer-credits p,
  section.section-footer .footer-credits-wrapper .footer-payment p {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  nav.section-header .button-wrapper {
    position: absolute;
    z-index: 1;
    top: 52px;
    right: 15px;
    display: flex;
    justify-content: flex-end;
  }
  nav.section-header .button-wrapper .nav-button {
    display: flex;
  }
  nav.section-header .header-management {
    flex-direction: column;
    align-items: center;
  }
  nav.section-header .header-management .free-delivery {
    width: 100%;
    text-align: center;
    margin: 0 0 10px 0;
  }
  nav.section-header .header-management .management-wrapper {
    width: 100%;
  }
  nav.section-header .header-management .management-logo {
    margin-right: 0;
  }
  nav.section-header .header-management .management-search-engine {
    padding-top: 25px;
  }
  nav.section-header .header-management .management-search-engine input {
    width: 300px;
  }
  nav.section-header .header-management .management-service {
    padding-top: 20px;
  }
  nav.section-header .header-management .management-service li .name {
    display: none;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox {
    justify-content: center;
    padding: 10px 0;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    color: #87bb41;
    font-size: 17px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  div.section-header-fixed-search .search-inner .management-search-engine input {
    width: 200px;
  }
  div.section-header-fixed-search .management-service {
    margin-left: 6px;
  }
  div.section-header-fixed-search .management-service li:not(:last-child) {
    margin-right: 10px;
  }
  div.section-header-fixed-search .management-service li .name {
    display: none;
  }
  .select-wrapper select {
    width: 130px;
    padding: 5px 20px 5px 10px;
    font-size: 12px;
  }
  section.section-welcome .welcome-image {
    right: -220px;
  }
  section.section-welcome .welcome-inner h1,
  section.section-welcome .welcome-inner h2 {
    font-size: 20px;
  }
  section.section-welcome .welcome-inner h1 strong,
  section.section-welcome .welcome-inner h2 strong {
    font-size: 35px;
  }
  section.section-cta .cta-inner-wrapper {
    padding: 50px 30px;
  }
  section.section-related .img-absolute {
    width: 80%;
    height: auto;
    left: 0;
  }
  section.kategoria-section-content .content-flexbox {
    flex-direction: column;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar {
    width: 100%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading {
    display: none;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close {
    display: block;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    padding: 50px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li {
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a {
    font-size: 18px;
    padding: 8px 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul {
    padding-left: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    margin-bottom: 40px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body {
    width: 100%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-text {
    font-size: 11px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid {
    margin-left: 20px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product {
    flex-direction: column;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery {
    pointer-events: none;
    width: 100%;
    padding-top: 40px;
    order: 1;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info {
    width: 100%;
    order: 2;
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox {
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox .select-wrapper {
    width: auto;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price,
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .quantity-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .wrapper-inner,
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .quantity-wrapper .wrapper-inner {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description ul {
    text-align: left;
  }
  section.section-footer .footer-advantages-wrapper .footer-advantages .advantage-service {
    margin-top: 20px;
    justify-content: space-around;
    width: 40%;
  }
  section.section-footer .footer-advantages-wrapper .footer-advantages .advantage-service p {
    font-size: 14px;
  }
}
@media (max-width: 575.98px) {
  nav.section-header .header-flexbox-wrapper .header-flexbox {
    justify-content: space-around;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-social-media p {
    display: none;
  }
  .breadcrumb-wrapper {
    display: none;
  }
  .products-flexbox {
    justify-content: space-between;
  }
  .products-flexbox .product-service .btn-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .products-flexbox .product-service .btn-wrapper .btn-product {
    padding: 5px 7px;
    margin: 0 7px;
    font-size: 13px;
  }
  .products-flexbox .product-service .btn-wrapper .btn-product svg {
    width: 16px;
  }
  .products-flexbox .product-service:nth-of-type(4) {
    display: flex;
  }
  .products-flexbox.grid-list .product-service header {
    min-width: 40%;
  }
  section.section-welcome .welcome-inner h1 strong,
  section.section-welcome .welcome-inner h2 strong {
    font-size: 50px;
  }
  section.section-welcome .welcome-image {
    right: -270px;
  }
  section.section-welcome .overlay-img {
    display: block;
  }
  section.section-related .related-flexbox .flexbox-service .service-product {
    width: 47%;
  }
  section.section-related .related-flexbox .flexbox-service .service-product:last-of-type {
    display: none;
  }
  section.kategoria-section-content,
  section.static-page-section-content,
  section.kontakt-section-welcome {
    padding: 40px 0 75px 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox {
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox .product-service {
    width: 90%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .wrapper-inner .btn,
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .quantity-wrapper .wrapper-inner .btn {
    margin-left: 0;
    margin-top: 20px;
  }
  div.panel-klienta-koszyk-section-content {
    padding: 50px 0 0 0 !important;
  }
  section.static-page-section-content {
    text-align: center;
  }
  section.kontakt-section-welcome .welcome-heading {
    flex-direction: column;
    align-items: center;
  }
  section.kontakt-section-welcome .welcome-heading .heading-contact {
    text-align: center;
  }
  section.kontakt-section-welcome .welcome-maps {
    flex-direction: column;
  }
  section.kontakt-section-welcome .welcome-maps .map-service .service-description {
    width: 50%;
  }
  section.kontakt-section-welcome .welcome-maps .map-service .service-description .name {
    font-size: 18px;
  }
  section.kontakt-section-welcome .welcome-maps .map-service .service-description address {
    font-size: 14px;
  }
  section.kontakt-section-welcome .welcome-maps .map-service .service-iframe {
    width: 50%;
  }
}
@media (max-width: 440px) {
  .management-search-engine .btn-search {
    width: 35px;
    height: 40px;
    min-width: 35px;
  }
  .management-service li a .icon-wrapper {
    width: 35px;
    height: 40px;
    min-width: 35px;
  }
  nav.section-header .header-management .management-search-engine .btn-search {
    width: 35px;
    height: 40px;
    min-width: 35px;
  }
  nav.section-header .header-management .management-search-engine input {
    width: 250px;
  }
  div.section-header-fixed-search {
    padding: 15px 0;
  }
  div.section-header-fixed-search .management-service {
    margin-left: 15px;
  }
  div.section-header-fixed-search .management-service li:not(:last-child) {
    margin-right: 5px;
  }
  div.section-header-fixed-search .search-inner .management-search-engine input {
    width: 150px;
    height: 40px;
  }
  div.section-header-fixed-search .search-inner .inner-logo img {
    height: 35px;
    margin: -12px 5px 0 0;
  }
  section.section-related {
    padding: 20px 0 50px 0;
  }
  section.section-related .related-flexbox .flexbox-service {
    justify-content: center;
  }
  section.section-related .related-flexbox .flexbox-service .service-product {
    width: 80%;
  }
  section.section-related .related-flexbox .flexbox-service .service-product .service-image-wrapper {
    height: 230px;
  }
  section.section-cta .cta-inner-wrapper .cta-inner {
    padding: 20px;
  }
  section.section-cta .cta-inner-wrapper .cta-inner h2 {
    font-size: 28px;
  }
  .products-flexbox {
    justify-content: center;
  }
  .products-flexbox .product-service {
    width: 90%;
  }
}
