// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  section.section-welcome {
    overflow: hidden;

    .welcome-image {
      right: -140px;
    }
  }

  .products-flexbox {
    .product-service {
      width: 48%;
    }
  }

  section.kategoria-section-content {
    .content-flexbox {
      .flexbox-body {
        .products-flexbox {
          .product-service {
            width: 48%;
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  nav.section-header {
    .header-management {
      padding: 30px 0;
      align-items: flex-end;

      .management-logo {
        margin-right: 20px;

        img {
          height: 70px;
        }
      }

      .management-service {
        margin-left: 6px;

        li:not(:last-child) {
          margin-right: 12px;
        }
      }

      .management-search-engine {
        input {
          width: 200px;
        }
      }
    }

    .header-flexbox-wrapper {
      .header-flexbox {
        .flexbox-categories {
          margin-right: 0;

          li {
            button {
              font-size: 13px;
            }
          }
        }

        .flexbox-nav-wrapper {
          .flexbox-nav-inner {
            .flexbox-nav {
              li {
                a {
                  font-size: 13px;
                  padding: 20px 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  div.section-header-fixed-search {
    .search-inner {
      .management-search-engine {
        input {
          width: 250px;
        }
      }
    }
  }

  .products-flexbox {
    &.grid-list {
      .product-service {
        header {
          min-width: 25%;
        }
      }
    }
  }

  section.section-related {
    padding: 30px 0 75px 0;

    .related-flexbox {
      flex-direction: column;

      .service-heading-mobile {
        display: block;
        text-align: center;
        margin-top: 30px;
      }

      .service-heading {
        display: none;
      }

      .flexbox-service {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .service-product {
          flex-direction: column;
          width: 32%;

          .service-image-wrapper {
            height: 150px;
            min-width: 130px;
          }

          .service-description {
            padding-left: 0;
            padding-top: 10px;

            .name,
            .price {
              text-align: center;
            }
          }
        }
      }
    }
  }

  section.kontakt-section-welcome {
    .welcome-maps {
      flex-direction: column;

      .map-service {
        display: flex;
        align-items: center;
        width: 100%;

        &:not(:first-child) {
          margin-top: 30px;
        }

        .service-description {
          width: 40%;
          padding-right: 20px;
        }

        .service-iframe {
          width: 60%;
          margin-top: 0;
        }
      }
    }
  }

  section.section-footer {
    .footer-flexbox {
      flex-direction: column;
      align-items: center;

      .flexbox-service {
        margin-top: 20px;

        ul {
          text-align: center;

          &.list-social {
            justify-content: center;
          }
        }
      }
    }

    .footer-credits-wrapper {
      .footer-credits,
      .footer-payment {
        flex-direction: column;

        p {
          text-align: center;
        }
      }
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  nav.section-header {
    .button-wrapper {
      position: absolute;
      z-index: 1;
      top: 52px;
      right: 15px;
      display: flex;
      justify-content: flex-end;

      .nav-button {
        display: flex;
      }
    }

    .header-management {
      flex-direction: column;
      align-items: center;

      .free-delivery {
        width: 100%;
        text-align: center;
        margin: 0 0 10px 0;
      }

      .management-wrapper {
        width: 100%;
      }

      .management-logo {
        margin-right: 0;
      }

      .management-search-engine {
        padding-top: 25px;

        input {
          width: 300px;
        }
      }

      .management-service {
        padding-top: 20px;

        li {
          .name {
            display: none;
          }
        }
      }
    }

    .header-flexbox-wrapper {
      .header-flexbox {
        justify-content: center;
        padding: 10px 0;

        .flexbox-nav-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          overflow-y: scroll;
          background: #fff;
          z-index: 555;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;

          .nav-button-close {
            display: block;
          }

          .flexbox-nav-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 100%;
            padding: 50px;
            margin-right: 0;

            .flexbox-nav {
              flex-direction: column;

              li {
                a {
                  color: @color1;
                  font-size: 17px;
                }
              }
            }
          }

          &.active {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  div.section-header-fixed-search {
    .search-inner {
      .management-search-engine {
        input {
          width: 200px;
        }
      }
    }

    .management-service {
      margin-left: 6px;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }

        .name {
          display: none;
        }
      }
    }
  }

  .select-wrapper {
    select {
      width: 130px;
      padding: 5px 20px 5px 10px;
      font-size: 12px;
    }
  }

  section.section-welcome {
    .welcome-image {
      right: -220px;
    }

    .welcome-inner {
      h1,
      h2 {
        font-size: 20px;

        strong {
          font-size: 35px;
        }
      }
    }
  }

  section.section-cta {
    .cta-inner-wrapper {
      padding: 50px 30px;
    }
  }

  section.section-related {
    .img-absolute {
      width: 80%;
      height: auto;
      left: 0;
    }
  }

  section.kategoria-section-content {
    .content-flexbox {
      flex-direction: column;

      .flexbox-sidebar {
        width: 100%;

        .heading {
          display: none;
        }

        .category-nav-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          overflow-y: scroll;
          background: #fff;
          z-index: 555;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;

          .nav-button-close {
            display: block;
          }

          .category-nav-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            min-height: 100%;
            padding: 50px;

            .category-nav {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;

              li {
                text-align: center;

                a {
                  font-size: 18px;
                  padding: 8px 0;
                }

                ul {
                  padding-left: 0;
                }
              }
            }
          }

          &.active {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
          }
        }

        .nav-button {
          display: flex;
          justify-content: center;
          padding: 15px 0;
          margin-bottom: 40px;
        }
      }

      .flexbox-body {
        width: 100%;

        .body-heading {
          .heading-text {
            font-size: 11px;
          }

          .inner-wrapper {
            .heading-grid {
              margin-left: 20px;
            }
          }
        }

        .body-product {
          flex-direction: column;

          .product-gallery {
            pointer-events: none;
            width: 100%;
            padding-top: 40px;
            order: 1;
          }

          .product-info {
            width: 100%;
            order: 2;
            text-align: center;

            .features {
              .select-wrapper-flexbox {
                justify-content: center;

                .select-wrapper {
                  width: auto;
                }
              }
            }

            .price,
            .quantity-wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;

              .wrapper-inner {
                flex-wrap: wrap;
                justify-content: center !important;
              }
            }

            .description {
              ul {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  section.section-footer {
    .footer-advantages-wrapper {
      .footer-advantages {
        .advantage-service {
          margin-top: 20px;
          justify-content: space-around;
          width: 40%;

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  nav.section-header {
    .header-flexbox-wrapper {
      .header-flexbox {
        justify-content: space-around;

        .flexbox-social-media {
          p {
            display: none;
          }
        }
      }
    }
  }

  .breadcrumb-wrapper {
    display: none;
  }

  .products-flexbox {
    justify-content: space-between;

    .product-service {
      .btn-wrapper {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn-product {
          padding: 5px 7px;
          margin: 0 7px;
          font-size: 13px;

          svg {
            width: 16px;
          }
        }
      }

      &:nth-of-type(4) {
        display: flex;
      }
    }

    &.grid-list {
      .product-service {
        header {
          min-width: 40%;
        }
      }
    }
  }

  section.section-welcome {
    .welcome-inner {
      h1,
      h2 {
        strong {
          font-size: 50px;
        }
      }
    }

    .welcome-image {
      right: -270px;
    }

    .overlay-img {
      display: block;
    }
  }

  section.section-related {
    .related-flexbox {
      .flexbox-service {
        .service-product {
          width: 47%;

          &:last-of-type {
            display: none;
          }
        }
      }
    }
  }

  section.kategoria-section-content,
  section.static-page-section-content,
  section.kontakt-section-welcome {
    padding: 40px 0 75px 0;
  }

  section.kategoria-section-content {
    .content-flexbox {
      .flexbox-body {
        .products-flexbox {
          justify-content: center;
          .product-service {
            width: 90%;
          }
        }
        .body-product {
          .product-info {
            .price,
            .quantity-wrapper {
              .wrapper-inner {
                .btn {
                  margin-left: 0;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  div.panel-klienta-koszyk-section-content {
    padding: 50px 0 0 0 !important;
  }

  section.static-page-section-content {
    text-align: center;
  }

  section.kontakt-section-welcome {
    .welcome-heading {
      flex-direction: column;
      align-items: center;

      .heading-contact {
        text-align: center;
      }
    }

    .welcome-maps {
      flex-direction: column;

      .map-service {
        .service-description {
          width: 50%;

          .name {
            font-size: 18px;
          }

          address {
            font-size: 14px;
          }
        }

        .service-iframe {
          width: 50%;
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 440px) {
  .management-search-engine {
    .btn-search {
      width: 35px;
      height: 40px;
      min-width: 35px;
    }
  }

  .management-service {
    li {
      a {
        .icon-wrapper {
          width: 35px;
          height: 40px;
          min-width: 35px;
        }
      }
    }
  }

  nav.section-header {
    .header-management {
      .management-search-engine {
        .btn-search {
          width: 35px;
          height: 40px;
          min-width: 35px;
        }

        input {
          width: 250px;
        }
      }
    }
  }

  div.section-header-fixed-search {
    padding: 15px 0;

    .management-service {
      margin-left: 15px;

      li {
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    .search-inner {
      .management-search-engine {
        input {
          width: 150px;
          height: 40px;
        }
      }

      .inner-logo {
        img {
          height: 35px;
          margin: -12px 5px 0 0;
        }
      }
    }
  }

  section.section-related {
    padding: 20px 0 50px 0;

    .related-flexbox {
      .flexbox-service {
        justify-content: center;

        .service-product {
          width: 80%;

          .service-image-wrapper {
            height: 230px;
          }
        }
      }
    }
  }

  section.section-cta {
    .cta-inner-wrapper {
      .cta-inner {
        padding: 20px;

        h2 {
          font-size: 28px;
        }
      }
    }
  }

  .products-flexbox {
    justify-content: center;

    .product-service {
      width: 90%;
    }
  }
}
