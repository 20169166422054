@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,500,600,700,800,900&display=swap&subset=latin-ext");

@font-face {
  font-family: "altairthin";
  src: url("../fonts/altair-thintrial-webfont.woff2") format("woff2"),
    url("../fonts/altair-thintrial-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "altairbold";
  src: url("../fonts/altair.bold-webfont.woff2") format("woff2"),
    url("../fonts/altair.bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  min-height: 100vh;
  height: 100%;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }
}

@color1: #87bb41;
@color2: #fc821b;

.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;

  .alert-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    transform: translateX(8px);
    padding: 50px 0;

    .service-inner {
      padding: 50px;
      background: #fff;
      width: 100%;
      box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
      text-align: center;
      border-top: 5px solid #fff;
      position: relative;
      transform: scale(0.8);
      transition: all 0.5s;

      .inner-dismiss {
        top: 10px;
        right: 10px;
        position: absolute;
        border: 0;
        background: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        padding: 5px;
        outline: none;

        img {
          height: 15px;
        }
      }

      &.success {
        border-top: 5px solid #78b042;
      }

      &.warning {
        border-top: 5px solid #d51a27;
      }

      &.info {
        border-top: 5px solid #1a88d5;
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .alert-service {
      .service-inner {
        transform: none;
      }
    }
  }
}

.select-wrapper {
  position: relative;

  select {
    cursor: pointer;
    padding: 5px 100px 5px 10px;
    border: 1px solid @color2;
    border-radius: 4px;
    background: #fff;
    height: 31px;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 13px;
    appearance: none;
    outline: none;
    display: block;
    color: #666;
    width: 100%;
  }

  &:after {
    cursor: pointer;
    content: "";
    height: 10px;
    width: 10px;
    background: url("../img/pictures/down-chevron.svg") center;
    background-size: cover;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin-bottom: 0;

  .radio-box {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-transform: none;
    color: #414141;
    font-weight: 300;
    text-align: left;
    transition: all 0.3s;

    &:before {
      content: "";
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      display: block;
      background: url("../img/pictures/checkbox.svg") no-repeat;
      margin-right: 10px;
      transition: all 0.3s;
    }
  }

  input {
    visibility: hidden;
    position: absolute;
    width: auto !important;
  }
}

.checkbox-wrapper > input:checked + .radio-box {
  font-weight: 700;

  &:before {
    background: url("../img/pictures/checkbox-checked.svg") no-repeat;
  }
}

.breadcrumb-wrapper {
  padding: 20px 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 35%,
    rgba(245, 245, 245, 1) 100%
  );
  margin-bottom: 30px;

  .breadcrumb {
    border-radius: 0;
    padding: 0;
    background: none;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;

    .breadcrumb-item {
      font-size: 12px;
      font-weight: 400;

      a {
        color: @color1;
        transition: all 0.4s;

        svg {
          display: none;
        }

        &:hover {
          color: @color2;
        }
      }
    }

    .breadcrumb-item + .breadcrumb-item::before {
      font-weight: 200;
    }
  }
}

.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-service {
    padding: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .service-inner {
      background: #fff;
      width: 700px;
      max-width: 100%;
      padding: 50px 25px 25px 25px;
      text-align: center;
      position: relative;

      .service-button {
        position: absolute;
        top: 20px;
        right: 20px;
        border: none;
        background: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-bar {
          display: block;
          position: absolute;
          width: 20px;
          height: 2px;
          background: #222;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }

      .service-content {
        .heading {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .btn {
          display: flex;
          justify-content: center;
          margin-top: 5px;
        }
      }

      .service-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-link {
          padding: 0;
          text-transform: uppercase;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &.grid-list {
    .product-service {
      width: 100% !important;
      flex-direction: row;
      justify-content: flex-start;

      &:hover {
        border: 1px solid transparent;
      }

      .btn-wrapper {
        justify-content: flex-start;

        .btn-product {
          &:first-of-type {
            margin-right: 20px;
          }
        }
      }

      .badge-wrapper {
        top: 0;
      }

      header {
        min-width: 17%;
        margin-right: 15px;
      }

      .service-image-wrapper {
        &.active {
          &:after {
            left: auto;
            right: 0;
          }
        }
      }

      .name {
        margin-top: 0;
        text-align: left;

        &.grid-view {
          display: none;
        }

        &.list-view {
          display: block;
        }
      }

      .price {
        text-align: left;
      }

      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }
}

.product-service {
  margin-top: 40px;
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: all 0.4s;
  border: 1px solid transparent;
  padding: 10px;

  &:hover {
    border: 1px solid @color1;
  }

  &.active {
    border: 1px solid @color1;
  }

  .badge-wrapper {
    position: absolute;
    z-index: 111;
    top: 10px;
    left: 10px;
    width: 100%;

    .badge {
      width: 70px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 10px;
      color: #fff;
      border-radius: 3px;
      margin-bottom: 10px;

      &.promotion {
        background: tomato;
        display: none;
      }

      &.new {
        background: #fcd533;
        display: none;
      }

      &.bestseller {
        background: dodgerblue;
        display: none;
      }

      &.fresh {
        background: olivedrab;
        display: none;
      }
    }
  }

  .service-image-wrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
    display: block;
    transition: all 0.4s;
    position: relative;

    .image-cover {
      position: absolute;
      z-index: 121;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 0.4s;

      .cover-inner {
        background: @color1;
        height: 50px;
        width: 50px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 40px;
      }
    }

    .service-image {
      text-align: center;
      position: relative;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      border-radius: 4px;

      img {
        width: 100%;
      }
    }
  }

  .name {
    text-align: center;
    margin-top: 15px;
    color: #222;
    font-weight: 400;
    font-size: 14.5px;
    display: block;
    transition: all 0.4s;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &.list-view {
      display: none;
    }
  }

  .footer {
    position: relative;
    min-height: 155px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .product-panel-wrapper,
    .product-alert-wrapper {
      position: absolute;
      z-index: 222;
      left: 0;
      bottom: -100%;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      background-color: rgba(#f5f5f5, 0.95);
      padding: 20px 10px;
      transition: all 350ms;
      display: flex;
      align-items: center;
      justify-content: center;

      .panel-button-close {
        position: absolute;
        z-index: 333;
        display: block;
        width: 100%;
        top: 5px;
        right: 0;

        .container-fluid {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          margin-right: -10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .button-bar {
            display: block;
            position: absolute;
            width: 15px;
            height: 1px;
            background: #222;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .alert-wrapper-inner {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 12px;

        p {
          line-height: 20px;
        }
      }

      &.active {
        bottom: 0;
        opacity: 1;
        visibility: visible;
      }

      &.success {
        border-top: 5px solid #78b042;
      }

      &.warning {
        border-top: 5px solid #d51a27;
      }

      &.info {
        border-top: 5px solid #1a88d5;
      }
    }

    .quantity-wrapper {
      margin-top: 10px;

      .wrapper-inner {
        display: flex;
        justify-content: center;
        align-items: center;

        .input-number {
          width: 70px;
          padding: 0 12px;
          margin: 0 10px;
          text-align: center;
          outline: none;
          background: #e5e5e5;
          border-radius: 4px;
          border: none;
        }

        .input-number,
        .input-number-decrement,
        .input-number-increment {
          height: 40px;
          user-select: none;
        }

        .input-number-decrement,
        .input-number-increment {
          min-width: 40px;
          border-radius: 4px;
          background: @color2;
          color: #fff;
          text-align: center;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 0.4s;
          cursor: pointer;
          border: none;
          outline: none;

          &:active {
            background: @color1;
            color: #fff;
          }
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          margin: 0;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }
      }

      .btn {
        color: #fff;
        background-color: @color1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 13px;
        transition: background-color 0.4s;
        margin-top: 10px;
        padding: 0;
        padding-left: 8px;
        width: 100%;

        .btn-icon {
          background: @color1;
          font-weight: 700;
          height: 30px;
          width: 30px;
          border-radius: 4px;
          font-size: 16px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.4s;
        }

        &:hover {
          background-color: darken(@color1, 5%);

          .btn-icon {
            background-color: darken(@color1, 5%);
          }
        }
      }
    }
  }

  .price {
    text-align: center;
    margin-top: 10px;
    color: @color1;
    font-size: 18px;
    letter-spacing: -0.5px;
    font-weight: 400;

    small {
      font-size: 18px;
      font-weight: 400;
      color: #444;
      margin-right: 5px;
      position: relative;
      display: none;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 1px;
        width: 100%;
        height: 1px;
        background: #999;
        transform: rotate(-10deg);
      }
    }

    span {
      font-weight: 400;
      color: #999;
      font-size: 12px;
    }
  }

  &.promotion {
    .promotion {
      display: flex !important;
    }

    .price {
      color: tomato;

      small {
        display: inline-block;
      }
    }
  }

  &.new {
    .new {
      display: flex !important;
    }
  }

  &.bestseller {
    .bestseller {
      display: flex !important;
    }
  }

  &.fresh {
    .fresh {
      display: flex !important;
    }
  }

  &:hover {
    .service-image-wrapper {
      .image-cover {
        opacity: 1;
      }
    }

    .name {
      color: @color1;
    }
  }

  .footer-inner {
    display: flex;
    flex-direction: column;
    min-height: 75px;
  }

  .btn-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .btn-product {
      padding: 5px 6px;
      border-radius: 4px;
      color: #fff;
      font-size: 12px;
      max-width: 100%;
      display: flex;
      align-items: center;
      background-color: @color1;
      transition: background-color 0.4s;
      cursor: pointer;
      border: none;

      &:hover {
        background-color: darken(@color1, 10%);
      }

      &:focus {
        outline: none;
        background-color: darken(@color1, 10%);
      }

      &:first-of-type {
        svg {
          width: 10px;
        }
      }

      svg {
        fill: #fff;
        width: 12px;
        margin-right: 5px;
      }
    }
  }

  &.service-hidden {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    visibility: hidden;
    opacity: 0;
  }
}

.pagination-nav {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  li {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      border-radius: 4px;
      color: @color1;
      text-transform: uppercase;
      transition: all 0.3s;
    }

    &:hover {
      a {
        color: @color2;
      }
    }

    &.active {
      a {
        font-weight: 700;
        color: #fff;
        background: @color2;
      }
    }
  }
}

nav.section-header {
  background: #f5f5f5;

  .button-wrapper {
    .nav-button {
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;
      cursor: pointer;
      border: 1px solid @color1;
      border-radius: 4px;
      width: 45px;
      height: 45px;

      .button-bar {
        height: 2px;
        width: 24px;
        background: @color1;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 4px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 4px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }
    }
  }

  &.subpage {
    background: #fff;

    .header-flexbox-wrapper {
      .header-flexbox {
        .flexbox-nav-wrapper {
          .flexbox-nav-inner {
            .flexbox-nav {
              li {
                &.active,
                &:hover {
                  a {
                    background: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .header-management {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 20px 0;

    .free-delivery {
      position: absolute;
      top: 10px;
      right: 0;
      margin-left: 8px;
      font-size: 12px;
      font-weight: 300;
      color: #777;

      strong {
        font-weight: 600;
        color: @color2;
      }
    }

    .management-logo {
      img {
        height: 100px;
      }
    }

    .management-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 72%;
    }
  }

  .header-flexbox-wrapper {
    background: @color1;

    .header-flexbox {
      position: relative;
      display: flex;
      justify-content: space-between;

      .flexbox-nav-wrapper {
        .nav-button-close {
          position: absolute;
          display: none;
          width: 100%;
          top: 20px;
          left: 0;

          .container {
            display: flex;
            justify-content: flex-end;

            &:before,
            &:after {
              content: none;
            }
          }

          button {
            outline: none;
            border: none;
            background: none;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .button-bar {
              display: block;
              position: absolute;
              width: 30px;
              height: 2px;
              background: #222;

              &:nth-child(1) {
                transform: rotate(45deg);
              }

              &:nth-child(2) {
                transform: rotate(-45deg);
              }
            }
          }
        }

        .flexbox-nav-inner {
          .flexbox-nav {
            display: flex;
            align-items: center;

            li {
              > a,
              button {
                padding: 0;
                background: none;
                border: none;
                color: #fff;
                cursor: pointer;
                font-weight: 500;
                font-size: 16px;
                letter-spacing: 0.2px;
                outline: none;
                padding: 20px 17px;
                display: block;
                transition: all 0.4s;
              }

              &.active,
              &:hover {
                > a,
                button {
                  background: #f5f5f5;
                  color: @color1;
                }
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      .flexbox-categories {
        margin-right: 160px;
        display: flex;
        align-items: center;

        li {
          > a,
          button {
            padding: 0;
            background: none;
            border: none;
            color: #fff;
            cursor: pointer;
            font-weight: 800;
            font-size: 16px;
            letter-spacing: 0.2px;
            outline: none;
            padding: 20px 17px;
            display: flex;
            align-items: center;
            transition: all 0.4s;

            svg {
              fill: #fff;
              height: 20px;
              width: 20px;
              margin-right: 8px;
              transition: all 0.4s;
            }
          }

          .dropdown-menu {
            margin: 0;
            border-radius: 0;
            padding: 5px 0;
            border-top: none;
            border-color: @color1;
            background: #f5f5f5;
            margin-left: -1px;

            .dropdown-item {
              padding: 10px 12px;
              font-size: 15px;

              &:first-letter {
                text-transform: uppercase;
              }

              &:hover {
                background: @color1;
                color: #fff;
              }
            }
          }

          &.active,
          &:hover {
            button {
              background: #f5f5f5;
              color: @color1;

              svg {
                fill: @color1;
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .flexbox-social-media {
        display: flex;
        align-items: center;

        p {
          font-size: 12px;
          color: #fff;
          margin-right: 20px;
        }

        ul {
          display: flex;
          align-items: center;

          li {
            a {
              img {
                height: 20px;
              }
            }

            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.management-search-engine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s;

  &.relative {
    position: relative;
  }

  input {
    width: 400px;
    height: 45px;
    padding: 10px 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    display: block;
    background: #d9d9d9;
    margin: 0 7px 0 0;
    outline: none;
    transition: all 0.4s;

    &:focus {
      border-color: @color2;
    }

    &::placeholder {
      font-size: 13px;
      font-weight: 400;
      color: #000;
    }
  }

  .btn-search {
    height: 45px;
    min-width: 45px;
    background: @color1;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(1px);
    transition: background 0.4s;

    svg {
      width: 18px;
      height: 18px;
      fill: #fff;
    }

    &:hover {
      background: darken(@color1, 10%);
    }
  }

  .search-content {
    position: absolute;
    top: 55px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 444;
    right: 0;
    width: 700px;
    height: 450px;
    overflow-y: auto;
    background-color: #fff;
    padding: 15px;
    transition: all 200ms;
    display: flex;
    justify-content: space-between;

    &.active {
      opacity: 1;
      visibility: visible;
      box-shadow: 0 2px 9px 0 rgba(16, 47, 102, 0.16);
    }

    .products-content {
      width: 60%;
      padding-right: 30px;

      .btn-search-default {
        padding: 5px 6px;
        border-radius: 4px;
        color: #fff;
        font-size: 12px;
        max-width: 100%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        background-color: #87bb41;
        transition: background-color 0.4s;
        cursor: pointer;
        border: none;

        &:hover {
          background-color: darken(@color1, 10%);
        }

        &:focus {
          outline: none;
          background-color: darken(@color1, 10%);
        }

        &:first-of-type {
          svg {
            width: 10px;
          }
        }

        svg {
          fill: #fff;
          width: 12px;
          margin-right: 5px;
        }
      }

      .product-panel-wrapper,
      .product-alert-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: 555;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          opacity: 1;
          visibility: visible;
        }

        .wrapper-content {
          pointer-events: visible;
          padding: 50px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 400px;
          height: 250px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;

          &.success {
            border-top: 5px solid #78b042;
          }

          &.warning {
            border-top: 5px solid #d51a27;
          }

          &.info {
            border-top: 5px solid #1a88d5;
          }
        }

        .btn-search-default {
          margin-top: 10px;
          padding: 5px 20px;
          font-size: 13px;
        }

        .panel-button-close {
          position: absolute;
          z-index: 333;
          display: block;
          width: 100%;
          top: 5px;
          right: 0;

          .container-fluid {
            display: flex;
            justify-content: flex-end;

            &:before,
            &:after {
              content: none;
            }
          }

          .btn-panel-close {
            outline: none;
            border: none;
            background: none;
            padding: 15px;
            margin-right: -10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .button-bar {
              display: block;
              position: absolute;
              width: 15px;
              height: 1px;
              background: #222;

              &:nth-child(1) {
                transform: rotate(45deg);
              }

              &:nth-child(2) {
                transform: rotate(-45deg);
              }
            }
          }
        }

        p {
          line-height: 26px;
          font-size: 15px;
        }

        .alert-wrapper-inner {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 12px;
        }

        .quantity-wrapper {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .wrapper-inner {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .input-number {
              width: 70px;
              padding: 0 12px;
              margin: 0 10px;
              text-align: center;
              outline: none;
              background: #e5e5e5;
              border-radius: 4px;
              border: none;
            }

            .input-number,
            .input-number-decrement,
            .input-number-increment {
              height: 40px;
              user-select: none;
            }

            .input-number-decrement,
            .input-number-increment {
              min-width: 40px;
              border-radius: 4px;
              background: @color2;
              color: #fff;
              text-align: center;
              font-weight: 700;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: background 0.4s;
              cursor: pointer;
              border: none;
              outline: none;

              &:active {
                background: @color1;
                color: #fff;
              }
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              margin: 0;
            }

            input[type="number"] {
              -moz-appearance: textfield;
            }
          }
        }
      }

      .btn-wrapper {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .phrases-content {
      width: 40%;

      ul {
        li {
          a {
            font-size: 15px;
          }
        }
      }
    }

    .heading {
      display: flex;
      align-items: center;
      margin: 15px 0;

      h3 {
        font-size: 13px;
        display: inline-block;
      }

      .line {
        width: 40px;
        height: 0.5px;
        background-color: @color2;
        margin-left: 10px;
      }
    }

    ul {
      list-style: none;

      li {
        padding: 8px 5px;
        transition: transform 250ms;

        a {
          font-size: 12px;
          color: #000;
          display: flex;

          img {
            width: 40px;
            margin-right: 8px;
          }
        }

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

.management-service {
  display: flex;
  align-items: center;

  li {
    a {
      display: flex;
      align-items: center;

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        min-width: 45px;
        width: 45px;
        background: @color1;
        border-radius: 4px;
        transition: all 0.4s;

        svg {
          height: 20px;
          width: 20px;
          fill: #fff;
          transition: all 0.4s;
        }
      }

      .name {
        margin-left: 8px;
        font-size: 12px;
        font-weight: 300;
        color: @color1;

        strong {
          font-weight: 600;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}

div.section-header-fixed-search {
  position: fixed;
  z-index: 333;
  top: -150px;
  left: 0;
  width: 100%;
  background: #f5f5f5;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  transition: all 0.4s;

  .search-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inner-logo {
      img {
        height: 40px;
      }
    }

    .management-search-engine {
      input {
        width: 500px;
      }
    }
  }

  &.active {
    top: 0;
  }
}

section.section-welcome {
  position: relative;
  background: #f5f5f5;

  .welcome-image {
    right: 0;
    top: 0;
    height: 100%;
    position: absolute;
  }

  .overlay-img {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, 0.6);
  }

  .owl-carousel-banner {
    .owl-controls {
      .owl-dots {
        position: absolute;
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;

        .owl-dot {
          margin: 5px;

          span {
            background: transparent;
            border: 1px solid #fff;
            width: 8px;
            height: 8px;
            margin: 2.5px;
            transition: all 0.3s;
          }

          &.active {
            span {
              margin: 0;
              background: @color1;
              border: 1px solid transparent;
              width: 13px;
              height: 13px;
            }
          }
        }
      }

      .owl-nav {
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .owl-prev {
          margin: 0 0 50px 0;
          background: none;
          padding: 0;
          position: relative;
          z-index: 333;

          &:before {
            content: "";
            width: 20px;
            height: 37px;
            background: url("../img/pictures/prev.svg") center;
            background-size: cover;
            display: block;
          }
        }

        .owl-next {
          margin: 0 0 50px 0;
          background: none;
          padding: 0;
          position: relative;
          z-index: 333;

          &:before {
            content: "";
            width: 20px;
            height: 37px;
            background: url("../img/pictures/next.svg") center;
            background-size: cover;
            display: block;
          }
        }
      }
    }
  }

  .welcome-inner {
    position: relative;
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .owl-carousel-welcome {
      .owl-controls {
        display: none;
      }
    }

    h1,
    h2 {
      color: @color1;
      font-size: 30px;
      font-weight: 300;

      strong {
        font-size: 70px;
        font-weight: 500;
        font-family: "Playfair Display", serif;
      }
    }

    a {
      margin-top: 20px;
      padding-left: 4px;
      color: #333;
      display: flex;
      align-items: center;

      .btn-icon {
        background: #b9b9b9;
        height: 23px;
        width: 23px;
        border-radius: 4px;
        margin-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.4s;

        img {
          height: 12px;
        }
      }

      &:hover {
        .btn-icon {
          margin-left: 18px;
          background: @color1;
        }
      }
    }
  }
}

.owl-carousel {
  .products-flexbox {
    margin-top: -40px;

    .product-service {
      width: 100%;
    }
  }

  .owl-controls {
    .owl-nav {
      top: -60px;
      right: 0;
      position: absolute;
      display: flex;
      justify-content: space-between;

      .owl-prev {
        margin: 0 10px 0 0;
        background: #eee;
        padding: 4px 12px;
        position: relative;
        z-index: 333;

        &:before {
          content: "";
          width: 7px;
          height: 12px;
          background: url("../img/pictures/prev.svg") center;
          background-size: cover;
          display: block;
        }
      }

      .owl-next {
        margin: 0;
        background: #eee;
        padding: 4px 12px;
        position: relative;
        z-index: 333;

        &:before {
          content: "";
          width: 7px;
          height: 12px;
          background: url("../img/pictures/next.svg") center;
          background-size: cover;
          display: block;
        }
      }
    }

    .owl-dots {
      margin-top: 20px;

      .owl-dot {
        &.active {
          span {
            background: @color1;
          }
        }
      }
    }
  }
}

section.section-products {
  padding-top: 40px;

  .section-heading {
    margin: 0 0 25px 0;
    text-align: center;
    font-family: "altairbold";
    color: #333;
    font-size: 28px;
  }
}

section.section-cta {
  padding-top: 75px;

  .cta-inner-wrapper {
    padding: 50px 150px;
    border-radius: 4px;

    .cta-inner {
      background: rgba(252, 130, 27, 0.8);
      padding: 50px;
      border-radius: 4px;
      width: 100%;
      text-align: center;

      h2 {
        font-family: "altairbold";
        color: #fff;
      }

      p {
        font-size: 14px;
        margin-top: 30px;
        color: #fff;
      }

      a {
        margin-top: 20px;
        padding-left: 4px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-icon {
          background: #b9b9b9;
          height: 23px;
          width: 23px;
          border-radius: 4px;
          margin-left: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.4s;

          img {
            height: 12px;
          }
        }

        &:hover {
          .btn-icon {
            margin-left: 18px;
            background: @color1;
          }
        }
      }
    }
  }
}

section.section-tags {
  padding-top: 60px;

  .section-heading {
    margin: 0 0 25px 0;
    text-align: center;
    font-family: "altairbold";
    color: #333;
    font-size: 28px;
  }

  .tags-inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .inner-service {
      margin: 5px;
      display: block;
      padding: 8px 15px;
      border-radius: 4px;
      font-size: 15px;
      color: #fff;
      background: @color1;
      transition: all 0.4s;

      &:hover {
        background: darken(@color1, 10%);
      }

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

section.section-related {
  margin-top: 75px;
  padding: 75px 0;
  background: #f5f5f5;
  position: relative;

  .img-absolute {
    position: absolute;
    height: 70%;
    bottom: -50px;
    left: -50px;
  }

  .related-flexbox {
    position: relative;
    display: flex;
    justify-content: space-between;

    .service-heading-mobile {
      font-family: "altairbold";
      color: #333;
      font-size: 19px;
      display: none;
    }

    .flexbox-service {
      width: 30%;

      .service-heading {
        font-family: "altairbold";
        color: #333;
        font-size: 19px;
      }

      .service-product {
        margin-top: 20px;
        display: flex;
        padding: 12px;
        background: #fff;
        border-radius: 4px;

        .service-image-wrapper {
          min-width: 100px;
          height: 100px;
          position: relative;
          display: block;
          transition: all 0.4s;
          position: relative;

          .image-cover {
            position: absolute;
            z-index: 101;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: all 0.4s;

            .cover-inner {
              background: @color1;
              height: 50px;
              width: 50px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-size: 40px;
            }
          }

          .service-image {
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            min-width: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;
            border-radius: 4px;

            img {
              width: 100%;
            }
          }
        }

        .service-description {
          padding-left: 15px;

          .name {
            color: #222;
            font-weight: 400;
            font-size: 14.5px;
            display: block;
            transition: all 0.4s;

            &.list-view {
              display: none;
            }
          }

          .price {
            margin-top: 10px;
            color: @color1;
            font-size: 18px;
            letter-spacing: -0.5px;
            font-weight: 400;

            small {
              font-size: 18px;
              font-weight: 400;
              color: #444;
              margin-right: 5px;
              position: relative;
              display: none;

              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 1px;
                width: 100%;
                height: 1px;
                background: #999;
                transform: rotate(-10deg);
              }
            }

            span {
              font-weight: 400;
              color: #999;
              font-size: 12px;
            }
          }
        }

        &.promotion {
          .price {
            color: tomato;

            small {
              display: inline-block;
            }
          }
        }

        &:hover {
          .service-image-wrapper {
            .image-cover {
              opacity: 1;
            }
          }

          .name {
            color: @color1;
          }
        }
      }
    }
  }
}

section.kategoria-section-content {
  padding: 0 0 75px 0;

  .content-flexbox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .flexbox-sidebar {
      width: 20%;
      position: relative;

      .heading {
        margin-bottom: 15px;

        p {
          font-size: 18px;
          font-family: "altairbold";
          color: #333;
          transform: translateY(1px);
        }
      }

      .nav-button {
        outline: none;
        padding: 10px;
        width: 100%;
        border: none;
        font-size: 11px;
        color: #fff;
        text-transform: uppercase;
        background: @color1;
        color: #fff;
        transition: all 0.3s;
        display: none;
        cursor: pointer;
        position: relative;

        &:hover {
          background: @color1;
        }
      }

      .category-nav-wrapper {
        position: relative;

        .nav-button-close {
          position: absolute;
          display: none;
          width: 100%;
          top: 20px;
          left: 0;

          .container {
            display: flex;
            justify-content: flex-end;

            &:before,
            &:after {
              content: none;
            }
          }

          button {
            outline: none;
            border: none;
            background: none;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .button-bar {
              display: block;
              position: absolute;
              width: 30px;
              height: 2px;
              background: #222;

              &:nth-child(1) {
                transform: rotate(45deg);
              }

              &:nth-child(2) {
                transform: rotate(-45deg);
              }
            }
          }
        }

        .category-nav-inner {
          .category-nav {
            padding-left: 17px;

            li {
              list-style: none;

              a {
                color: #333;
                font-size: 17px;
                transition: all 0.4s;
                display: block;

                &:hover {
                  color: @color1;
                }
              }

              &.active {
                > a {
                  font-weight: 700;
                  color: @color1;
                }
              }

              &:not(:last-child) {
                padding-bottom: 10px;
              }

              ul {
                padding-left: 17px;

                li {
                  padding-bottom: 0 !important;
                  border-bottom: none !important;
                  margin-bottom: 0 !important;

                  a {
                    padding: 5px 0;
                    font-size: 14px;
                    color: #777;
                  }
                }
              }
            }
          }

          .filter-nav {
            margin-top: 50px;

            .heading {
              &:not(:first-of-type) {
                margin-top: 50px;
              }
            }

            ul {
              padding-left: 17px;
            }

            .range-slider {
              width: 100%;
              margin: auto;
              margin-top: 20px;
              text-align: center;
              position: relative;

              .slider-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                input[type="number"] {
                  border: none;
                  margin: 0 5px;
                  outline: none;
                  text-align: center;
                  font-size: 14px;
                  height: 30px;
                  border-bottom: 1px solid @color2;
                  border-radius: 4px;
                  width: 100%;
                  -moz-appearance: textfield;
                }

                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                }

                input[type="number"]:invalid,
                input[type="number"]:out-of-range {
                  border: 1px solid #ff6347;
                }

                span {
                  display: block;
                  min-width: 12px;
                }

                p {
                  font-size: 13px;
                }
              }

              svg,
              input[type="range"] {
                position: absolute;
                left: 0;
                bottom: -40px;
              }

              input[type="range"] {
                -webkit-appearance: none;
                width: 100%;
              }

              input[type="range"]:focus {
                outline: none;
              }

              input[type="range"]:focus::-webkit-slider-runnable-track {
                background: #cba42d;
              }

              input[type="range"]:focus::-ms-fill-lower {
                background: #cba42d;
              }

              input[type="range"]:focus::-ms-fill-upper {
                background: #cba42d;
              }

              input[type="range"]::-webkit-slider-runnable-track {
                width: 100%;
                height: 3px;
                cursor: pointer;
                animate: 0.2s;
                background: @color1;
                border-radius: 1px;
                box-shadow: none;
                border: 0;
              }

              input[type="range"]::-webkit-slider-thumb {
                z-index: 2;
                position: relative;
                box-shadow: 0px 0px 0px #000;
                border: 1px solid @color1;
                height: 14px;
                width: 14px;
                border-radius: 25px;
                background: @color2;
                cursor: pointer;
                -webkit-appearance: none;
                margin-top: -5px;
              }

              input[type="range"]::-moz-range-track {
                width: 100%;
                height: 5px;
                cursor: pointer;
                animate: 0.2s;
                background: @color1;
                border-radius: 1px;
                box-shadow: none;
                border: 0;
              }

              input[type="range"]::-moz-range-thumb {
                z-index: 2;
                position: relative;
                box-shadow: 0px 0px 0px #000;
                border: 1px solid @color1;
                height: 18px;
                width: 18px;
                border-radius: 25px;
                background: @color2;
                cursor: pointer;
              }

              input[type="range"]::-ms-track {
                width: 100%;
                height: 5px;
                cursor: pointer;
                animate: 0.2s;
                background: transparent;
                border-color: transparent;
                color: transparent;
              }

              input[type="range"]::-ms-fill-lower,
              input[type="range"]::-ms-fill-upper {
                background: @color1;
                border-radius: 1px;
                box-shadow: none;
                border: 0;
              }

              input[type="range"]::-ms-thumb {
                z-index: 2;
                position: relative;
                box-shadow: 0px 0px 0px #000;
                border: 1px solid @color1;
                height: 18px;
                width: 18px;
                border-radius: 25px;
                background: @color2;
                cursor: pointer;
              }
            }

            .buttons-wrapper {
              margin-top: 90px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .btn-clear {
                color: #444;
                border: none;
                font-size: 12px;
                font-weight: 600;
                background: none;
                cursor: pointer;
                padding: 0;
                text-transform: uppercase;
              }

              .btn-filter {
                padding: 0;
                border: none;
                background: @color1;
                font-size: 12px;
                font-weight: 600;
                border-radius: 4px;
                color: #fff;
                padding: 3px 10px;
                cursor: pointer;
                text-transform: uppercase;
                transition: all 0.3s;

                &:hover {
                  background: darken(@color1, 10%);
                }
              }
            }
          }
        }
      }
    }

    .flexbox-body {
      width: 75%;

      .body-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .heading-text {
          font-size: 13px;
          color: #777;
          position: relative;
          text-align: center;
        }

        .inner-wrapper {
          display: flex;
          align-items: center;

          .heading-grid {
            margin-left: 30px;
            display: flex;

            a,
            button {
              margin-right: 7px;
              padding: 0;
              border: none;
              background: #fff;
              border-radius: 4px;
              outline: none;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 30px;
              width: 30px;
              transition: all 0.4s;

              img {
                width: 15px;
                transition: all 0.4s;
              }

              &:last-child {
                margin-right: 0;
              }

              &.active {
                background: @color2;
              }
            }
          }
        }
      }

      .products-flexbox {
        .product-service {
          width: 32%;
        }
      }

      .heading {
        margin-top: 50px;
        margin-bottom: 20px;
        text-align: center;
        font-family: "altairbold";
        color: #333;
        font-size: 28px;
      }

      .body-cta {
        margin-top: 40px;
        display: block;
        position: relative;
        transition: border-radius 0.4s;
        overflow: hidden;

        img {
          width: 100%;
        }

        .cta-inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          text-align: right;
          padding: 30px;

          p {
            font-weight: 300;
            font-size: 24px;
            color: #fff;

            strong {
              font-size: 31px;
              font-weight: 700;
            }
          }
        }

        &:hover {
          border-radius: 10px;
        }
      }

      .body-more {
        margin-top: 65px;
        text-align: center;

        .btn {
          width: 400px;
          max-width: 100%;
        }
      }

      .body-product {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .product-gallery {
          width: 38%;

          img {
            width: 100%;
            border-radius: 4px;
          }

          .lSSlideWrapper {
            border-radius: 4px;
            position: relative;
          }

          .lSAction {
            a {
              margin-top: 0;
              background: rgba(0, 0, 0, 0);
              transition: all 0.4s;

              &:hover {
                background: rgba(0, 0, 0, 0.1);
              }
            }

            .lSPrev {
              width: 25px;
              height: 100%;
              left: 0;
              top: 0;
              position: absolute;
              transform: none;

              &:after {
                content: "";
                display: block;
                width: 25px;
                height: 25px;
                background: url("../library/lightslider-master/dist/img/prev.svg")
                  center;
                background-size: cover;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }

            .lSNext {
              width: 25px;
              height: 100%;
              right: 0;
              top: 0;
              position: absolute;
              transform: none;

              &:after {
                content: "";
                display: block;
                width: 25px;
                height: 25px;
                background: url("../library/lightslider-master/dist/img/prev.svg")
                  center;
                background-size: cover;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%) rotate(180deg);
              }
            }
          }

          .lSGallery {
            margin-top: 23px !important;

            li {
              border: 1px solid transparent;
              opacity: 0.6;
              transition: all 0.3s;

              &.active,
              &:hover {
                opacity: 1;
                border: 1px solid @color2;
              }
            }
          }
        }

        .product-info {
          width: 55%;

          .badge-wrapper {
            display: flex;

            .badge {
              width: 70px;
              height: 25px;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-size: 10px;
              color: #fff;
              border-radius: 3px;
              margin-bottom: 10px;

              &.promotion {
                background: tomato;
                display: none;
              }

              &.new {
                background: olivedrab;
                display: none;
              }

              &.bestseller {
                background: dodgerblue;
                display: none;
              }

              &:not(:last-child) {
                margin-right: 20px;
              }
            }
          }

          h1 {
            font-size: 36px;
            font-weight: 400;
            color: @color1;
          }

          .code {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 300;

            strong {
              font-weight: 700;
              color: @color1;
            }
          }

          .description {
            padding-top: 30px;
            margin-top: 30px;
            border-top: 1px solid #e5e5e5;

            p {
              font-size: 14px;
            }

            ul {
              padding-left: 17px;

              li {
                font-size: 14px;
              }
            }
          }

          .features {
            margin-top: 20px;

            > * {
              &:not(:first-child) {
                margin-top: 10px;
              }
            }

            p {
              font-size: 16px;
              font-weight: 300;

              strong {
                font-weight: 700;
                color: #999;
              }
            }

            .select-wrapper-flexbox {
              display: flex;
              align-items: center;

              label {
                font-size: 16px;
                font-weight: 300;
                white-space: nowrap;
                margin-bottom: 0;
                margin-right: 20px;
              }

              .select-wrapper {
                width: 100%;
              }
            }
          }

          .download-list {
            margin-top: 20px;

            li {
              a {
                display: block;
                background: lighten(#efefefef, 3%);
                padding: 8px 10px;
                font-size: 13px;
                border-left: 2px solid transparent;
                color: #333;

                &:hover {
                  border-color: @color2;
                  color: @color1;
                }
              }

              &.heading {
                margin-top: 0;
                text-align: left;
              }

              &:nth-child(even) {
                a {
                  background: #efefef;
                }
              }
            }
          }

          .price {
            margin-top: 40px;

            .price-brutto {
              color: @color1;
              font-size: 40px;
              font-weight: 500;
              line-height: 30px;
              display: flex;
              align-items: flex-end;

              small {
                font-size: 40px;
                font-weight: 400;
                color: #444;
                margin-right: 15px;
                position: relative;
                display: none;

                &:after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 1px;
                  width: 100%;
                  height: 1px;
                  background: #999;
                  transform: rotate(-10deg);
                }
              }

              span {
                font-weight: 400;
                color: #999;
                font-size: 15px;
                line-height: 15px;
                padding-left: 10px;
              }
            }

            .quantity-wrapper {
              margin-top: 30px;

              .wrapper-inner {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .input-number {
                  width: 70px;
                  padding: 0 12px;
                  margin: 0 10px;
                  text-align: center;
                  outline: none;
                  background: #e5e5e5;
                  border-radius: 4px;
                  border: none;
                }

                .input-number,
                .input-number-decrement,
                .input-number-increment {
                  height: 40px;
                  user-select: none;
                }

                .input-number-decrement,
                .input-number-increment {
                  min-width: 40px;
                  border-radius: 4px;
                  background: @color2;
                  color: #fff;
                  text-align: center;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: background 0.4s;
                  cursor: pointer;
                  border: none;
                  outline: none;

                  &:active {
                    background: @color1;
                    color: #fff;
                  }
                }

                input[type="number"]::-webkit-inner-spin-button,
                input[type="number"]::-webkit-outer-spin-button {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  appearance: none;
                  margin: 0;
                }

                input[type="number"] {
                  -moz-appearance: textfield;
                }
              }

              .btn {
                color: #fff;
                background-color: @color1;
                padding: 0 0 0 10px;
                display: flex;
                align-items: center;
                margin-left: 15px;
                transition: background-color 0.4s;

                .btn-icon {
                  background: @color1;
                  font-weight: 700;
                  height: 40px;
                  width: 40px;
                  border-radius: 4px;
                  font-size: 16px;
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  transition: background-color 0.4s;
                }

                &:hover {
                  background-color: darken(@color1, 5%);

                  .btn-icon {
                    background-color: darken(@color1, 5%);
                  }
                }
              }
            }
          }

          &.promotion {
            .promotion {
              display: flex !important;
            }

            .price {
              .price-brutto {
                color: tomato;

                small {
                  display: inline-block;
                }
              }
            }
          }

          &.new {
            .new {
              display: flex !important;
            }
          }

          &.bestseller {
            .bestseller {
              display: flex !important;
            }
          }
        }
      }
    }
  }
}

section.kontakt-section-welcome {
  padding: 0 0 75px 0;
  overflow: hidden;
  position: relative;

  .welcome-heading {
    z-index: 111;
    position: relative;
    display: flex;
    justify-content: space-between;

    h1 {
      font-size: 60px;
      font-family: "altairthin", sans-serif;
      color: #333;

      strong {
        font-size: 45px;
        color: @color1;
        font-family: "altairbold", sans-serif;

        span {
          color: @color2;
        }
      }
    }

    .heading-contact {
      text-align: right;
      font-family: "Montserrat", sans-serif;

      address {
        font-size: 17px;

        strong {
          font-size: 21px;
        }
      }

      ul {
        list-style: none;

        li {
          a {
            font-size: 22px;
            color: @color1;
            font-weight: 700;
          }
        }
      }
    }
  }

  .welcome-maps {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    .map-service {
      width: 31%;

      .service-description {
        text-align: center;

        .name {
          font-size: 21px;
        }

        address {
          font-size: 17px;
        }

        ul {
          list-style: none;

          li {
            a {
              font-size: 15px;
              color: @color1;
              font-weight: 700;
            }
          }
        }
      }

      .service-iframe {
        margin-top: 20px;
        position: relative;
        height: 200px;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 12px;
        }
      }
    }
  }
}

section.static-page-section-content {
  padding: 0 0 70px 0;

  .content-heading {
    text-align: center;

    h2 {
      font-size: 36px;
      font-weight: 400;
      font-family: "altairbold";
    }

    .date {
      margin-top: 15px;
      color: @color1;

      strong {
        margin-right: 10px;
      }
    }
  }

  .content-inner {
    margin-top: 40px;

    > * {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    p,
    li {
      font-size: 15px;
      line-height: 26px;
      color: #444;
    }

    ul,
    ol {
      padding-left: 17px;
    }

    .btn-default {
      color: #fff;
      background-color: @color1;
      padding: 7px 10px;
      transition: background-color 0.4s;
      display: inline-block;

      &:hover {
        background-color: darken(@color1, 10%);
      }
    }
  }

  .media-flexbox {
    padding-top: 50px;
    margin-top: calc(-5% / 4);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .flexbox-service-wrapper {
      margin-top: calc(5% / 4);
      border-radius: 2px;
      width: 19%;
      position: relative;
      overflow: hidden;

      .flexbox-service {
        width: 100%;
        padding-top: 66%;
        display: block;

        .service-inner {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;

          img {
            width: 100%;
            transition: all 0.3s;
          }
        }
      }

      &.service-hidden {
        margin-top: 0;
      }
    }
  }
}

section.section-footer {
  background: #f5f5f5;
  position: relative;

  .footer-advantages-wrapper {
    background: @color1;
    padding: 17px 0;

    .footer-advantages {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .advantage-service {
        display: flex;
        align-items: center;
        text-align: center;

        img {
          height: 40px;
          margin-right: 13px;
          transition: all 0.4s;
        }

        p {
          font-size: 13px;
          color: #fff;
          line-height: 16px;
          font-weight: 300;
          transition: color 0.4s;

          strong {
            font-weight: 600;
          }
        }

        &:hover {
          img {
            transform: translateY(-4px);
          }
        }
      }
    }
  }

  .footer-flexbox {
    display: flex;
    justify-content: space-between;
    position: relative;

    .flexbox-service {
      margin-top: 75px;

      &.service-about {
        .service-logo {
          img {
            height: 80px;
          }
        }

        .service-description {
          margin-top: 20px;

          p {
            color: #444;
            font-size: 14px;
          }
        }
      }

      ul {
        li {
          margin-top: 5px;

          &.heading {
            margin-top: 0;
            padding-bottom: 10px;
            font-size: 18px;
            color: #444;
          }

          a {
            font-size: 13px;
            color: #444;
            transition: all 0.3s;

            b {
              font-size: 16px;
            }

            span {
              font-size: 10px;
            }

            &:hover {
              color: @color2;
            }
          }

          p {
            font-size: 12px;
            color: #444;

            b {
              font-weight: 400;
              font-size: 14px;
            }

            span {
              font-size: 10px;
            }
          }
        }
      }

      .list-social {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          margin-right: 20px;

          a {
            img {
              height: 25px;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .alert {
    position: relative;
    background: none;
    border: none;
    border-radius: 4px;
    margin-top: 55px;
    padding: 30px;
    background: #e5e5e5;

    p {
      font-size: 14px;
      color: #444;
      font-weight: 300;

      a {
        color: @color2;
        cursor: pointer;
      }
    }
  }

  .footer-credits-wrapper {
    margin-top: 55px;
    padding: 25px 0;
    background: #e5e5e5;

    .footer-credits {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      p {
        font-size: 13px;
        color: #444;
        font-weight: 300;

        a {
          color: @color2;
        }
      }

      .footer-payment {
        display: flex;
        align-items: center;

        p {
          margin-right: 15px;
        }

        img {
          margin-right: 10px;
          height: 19px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
